var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contents_box"},[_c('table',{staticClass:"form_tab"},[_c('tr',[_vm._m(0),_c('td',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.userState.name1))]),_c('span',[_vm._v(_vm._s(_vm.userState.name2))])])])]),_c('tr',[_vm._m(1),_c('td',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.userState.kana1))]),_c('span',[_vm._v(_vm._s(_vm.userState.kana2))])])])]),_c('tr',[_vm._m(2),_c('td',[_c('p',[_vm._v("〒"+_vm._s(_vm.zipcodeDisp(_vm.userState.send_addr_zipcode)))]),_c('p',[_vm._v(_vm._s(_vm.userState.send_addr_detail1)+_vm._s(_vm.userState.send_addr_detail2))]),_c('p',[_vm._v(_vm._s(_vm.userState.send_addr_detail3))])])]),_c('tr',[_vm._m(3),_c('td',[_c('p',[_vm._v(_vm._s(_vm.userState.phone_number))])])]),_c('tr',[_vm._m(4),_c('td',[_c('p',[_vm._v(_vm._s(_vm.userState.email))])])]),(_vm.showPaymentMethod)?_c('tr',[_vm._m(5),_c('td',[_c('p',[_vm._v(_vm._s(_vm.paymentMethodDisp)+"払い")])])]):_vm._e(),_c('tr',[_vm._m(6),_c('td',{staticClass:"form_line_r"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.lineBreakToBR(_vm.userState.bikou1))}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("お名前")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("お名前（カナ）")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("集配先住所")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("電話番号")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("メールアドレス")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("お支払い方法")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"form_line_l"},[_c('p',{staticClass:"item2"},[_vm._v("備考")])])
}]

export { render, staticRenderFns }