<template>
<ul class="list">
  <li><p>宅配ボックスをご利用の場合は解除用のパスワードを記入ください</p></li>
  <li><p>集配時間の3時間前に確認メールが届きます</p></li>
  <li><p>最短で集荷時間から休業日を除く24時間後に配達可能です</p></li>
  <li><p>集配時間は最大で2時間ほど前後する可能性があります</p></li>
  <li><p>集配依頼確定後のキャンセルおよび日時変更はできかねます</p></li>
  <li><p>「請求書（後払いPaid）」をご利用の場合、株式会社ラクーンフィナンシャルが提供する請求代行サービスPaidより請求書が発行されます（月末締め翌月末払い）</p></li>
  <li><p>与信審査の結果次第ではサービスをご利用いただけないことがございますのであらかじめご了承願います</p></li>
</ul>
</template>

<script>
export default {
  name: 'wash-request-onetime-notice-messages',
  data() {
    return {}
  },
}
</script>
