<template>
<div class="contents_855">
  <header-bar active="wash-request-currents"></header-bar>

  <h1 class="title2">ご依頼中の集配情報</h1>

  <div class="contents_box">
    <div v-for="wreq in getItems">
      <h2 class="title3">集配{{wreq.dispNum}}
        <span v-if="wreq.request_type === '2'" class="wreq_week">曜日固定</span>
      </h2>
      <table class="form_tab form_line_top">
        <template v-if="!isBagDeliveryRequest(wreq)">
          <tr>
            <th>
              <p class="item2">集荷日時</p>
            </th>
            <td>
              <p :class="wreq.delivery_box_pw ? 'line_box br col2' : 'line_box col2'">
                {{wreq.sched_pickup_dt | dtFormat('m月d日（a）HH：MM')}}
              </p>
              <p v-if="wreq.delivery_box_pw" class="line_box delivery_box_pw col2">
                {{wreq.delivery_box_pw}}
              </p>
              <p class="line_box col3">
                <button class="btn1 btn_change radius4"
                        :disabled="!wreq.canChangePickupDt"
                        @click="changePickupDt(wreq)">
                  {{wreq.pickupChangeBtText}}
                </button>
                <button class="btn1 btn_change radius4"
                        :disabled="!wreq.canCancel"
                        @click="cancelWashRequest(wreq)">
                  集荷キャンセル
                </button>
              </p>
            </td>
          </tr>
          <tr>
            <th class="form_line_l">
              <p class="item2">配達日時</p>
            </th>
            <td class="form_line_r">
              <span v-if="wreq.pdSameTime">
                <span class="font_orange">次回集荷時に併せて配達します。</span>
              </span>
              <span v-else>
                <p class="line_box col2">
                  {{wreq.sched_delivery_dt | dtFormat('m月d日（a）HH：MM')}}
                </p>
                <p class="line_box col3">
                  <button
                    class="btn1 btn_change radius4"
                    :disabled="!wreq.canChangeDeliveryDt"
                    @click="changeDeliveryDt(wreq)"
                  >
                    {{wreq.deliveryChangeBtText}}
                  </button>
                </p>
              </span>
            </td>
          </tr>
          <tr>
            <th>
              <p class="item2">詳細情報</p>
            </th>
            <td>
              {{wreq.size}}／{{wreq.count}}個
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <th class="form_line_l">
              <p class="item2">配達日時</p>
            </th>
            <td class="form_line_r">
              <span>
                <p class="line_box col2">
                  {{wreq.sched_delivery_dt | dtFormat('m月d日（a）HH：MM')}}
                </p>
                <p class="line_box col3">
                  <button
                    class="btn1 btn_change radius4"
                    :disabled="!wreq.canChangeDeliveryDt"
                    @click="changeDeliveryDt(wreq)"
                  >
                    {{wreq.deliveryChangeBtText}}
                  </button>
                </p>
              </span>
            </td>
          </tr>
          <tr>
            <th>
              <p class="item2">詳細情報</p>
            </th>
            <td>
              <span class="bag-delivery-text mr4">バッグ配達</span>／{{wreq.count}}個
            </td>
          </tr>
        </template>
      </table>
    </div>
    <pager :itemCount="itemCount" :itemsPerPage="itemsPerPage" :pageRange="pageRange" @click="updatePage"></pager>

    <table class="form_tab" v-if="!noWashReqs">
      <tr>
        <th class="form_line_l"></th>
        <td class="form_line_r">
          <wash-request-notice-messages mongon-type="B">
          </wash-request-notice-messages>
        </td>
      </tr>
    </table>

    <div v-if="noWashReqs">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        現在ご依頼中の集配はございません
      </p>
      <p class="submit_bx1">
        <router-link :to="{name:'wash-request'}" class="glnavi_btn_on">
          集配依頼
        </router-link>
      </p>
    </div>

  </div>
</div>
</template>

<script>
import Vue from 'vue'
import washRequestMixin from '@/mixin/washRequestMixin'
import Pager from '@/components/lib/Pager';
import userApi from '@/api/user'
import { mapState, mapGetters } from 'vuex'
import { bagDeliveryBikouStr } from '@/consts/washRequest'

export default {
  name: 'wash-request-currents',
  components: {
    Pager
  },
  data() {
    return {
      washReqs: [],
      noWashReqs: false,
      itemCount: 0,
      itemsPerPage: 20,
      pageRange: 3,
      currentPage: 1,
    }
  },
  computed: {
    getItems: function() {
      let current = this.currentPage * this.itemsPerPage;
      let start = current - this.itemsPerPage;
      return this.washReqs.slice(start, current);
    },
  },
  mounted() {
    const token = this.$store.getters['localStorage/serverToken']
    if (!token) {
      this.$router.push({name: 'login'})
    }
    window.master.$promise.then(mst => {
      const maxWreqCount = 60
      userApi.getMyWashRequests().then(({ data }) => {
        // Business Rule No.25 依頼済みの集配予定はすべて表示
        this.washReqs = this.getWashRequestCurrents(data)
        this.washReqs = this.washReqs.map(e => {
          e.size = mst.lovsMap['size'][mst.planMap[e.plan_id].size].val + 'サイズ'
          e.pdSameTime = false
          //plan_idを_で区切って３つ目の値が1なら集配同時
          //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
          let planInfos = e.plan_id.split('_')
          if (planInfos.length > 2 && planInfos[2] == 1) {
            e.pdSameTime = true
          }
          if (planInfos[0] == 'tx') {
            e.size = '単発（45L）'
          }
          return e
        }).slice(0, maxWreqCount)
        this.itemCount = this.washReqs.length
        this.noWashReqs = this.washReqs.length === 0
      })
    })
  },
  mixins: [washRequestMixin],
  methods: {
    updatePage(page){
      this.currentPage = page;
    },
    changePickupDt(wreq) {
      this.$router.push({
        name: 'wash-request-change',
        params: {
          id: wreq.id
        }
      })
    },
    changeDeliveryDt(wreq) {
      this.$router.push({
        name: 'wash-request-change',
        params: {
          id: wreq.id
        }
      })
    },
    cancelWashRequest(wreq) {
      this.$router.push({
        name: 'wash-request-cancel',
        params: {
          id: wreq.id
        }
      })
    },
    isBagDeliveryRequest(wreq) {
      return wreq.bikou === bagDeliveryBikouStr
    },
  }
}
</script>

<style lang="scss" scoped>
.form_tab {
  .col2 {
    width: 184px;
  }
}

h2{
  position: relative;
}

.wreq_week{
  color: #fff;
  background-color: #ff7b45;
  padding: 2px 10px;
  position: absolute;
  right: 2%;
  top: 22%;
  font-size: 12px;
}

.bag-delivery-text {
  color: #fff;
  background-color: #78a65a;
  padding: 2px 4px;
  border-radius: 4px;
}
</style>
