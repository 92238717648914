<template>
  <tr>
    <th><p class="item3">お支払い方法<span class="font_orange">*</span></p></th>
    <td>
      <div class="wrap_input">
        <div v-for="elem in paymentMethods" class="slc">
          <input type="radio" :id="`option${elem.key}`" :value="elem.key" v-model="userState.payment_method_id"/>
          <label :for="`option${elem.key}`" class="ml5">{{elem.val}}払い</label>
        </div>
      </div>
      <p>
        <span class="iferror" v-show="userErrorState.errors.payment_method_unselectable">
          ▲ 個人のお客様は請求書払いを選択できません
        </span>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors.payment_method_onetime">
          ▲ 単発プランは請求書払いを選択できません
        </span>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors.payment_method_plans">
          ▲ 選択されたお申し込み内容は請求書払いを選択できません
        </span>
      </p>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import { useMaster } from '@/composables/useMaster'
import { getValidPaymentMethodKeys } from '@/utils/userUtils'

export default defineComponent({
  props: {
    isCompany: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const master = useMaster()
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    const validPaymentMethodKeys = computed(() => {
      return getValidPaymentMethodKeys(props.isCompany)
    })

    const paymentMethods = computed(() => {
      if (!master.lovs || !master.lovs.payment_method) {
        return []
      }
      return master.lovs.payment_method.filter(pm => {
        return validPaymentMethodKeys.value.includes(pm['key'])
      })
    })

    return {
      userState,
      userErrorState,
      // computed
      paymentMethods,
    }
  }
})
</script>

<style lang="scss" scoped>
.wrap_input {
  width: 100%;
  margin: 0 auto 0 auto;
  text-align: left;
  overflow: hidden;
}
.wrap_input .slc {
  float: left;
  margin: 10px 15px 0 0;
}

@media screen and (max-width: 640px) {
  .wrap_input .slc {
    float: none;
  }
}
</style>
