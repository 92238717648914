<template>
<ul class="list">
  <li><p>曜日固定を設定すると自動で集配依頼が行なわれます</p></li>
  <li><p>曜日固定の集配依頼数に対し、残個数が不足している場合は自動で追加購入が実行されます</p></li>
  <li><p>自動で追加購入が完了したあとの返金はできません</p></li>
  <li><p>設定済みの集配依頼で急遽不要になった場合は「変更/キャンセル」よりキャンセルすると残個数が1個分増えます</p></li>
  <li><p>当月23:59までに固定曜日の変更や解除を行なうと翌月から適用されます</p></li>
</ul>
</template>

<script>
export default {
  name: 'wash-request-notice-messages',
  props: {
    mongonType: {
      type: String
    }
  },
  data() {
    return {}
  },
}
</script>
