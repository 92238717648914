<template>
<div class="contents">
  <div class="search-box">
    <div class="ilblk">
      <div>操作日</div>
      <div>
        <my-calendar
          v-model="search.operationDate"
          width="75px"
        ></my-calendar>
      </div>
    </div>
    <div class="ilblk">
      <div>操作者</div>
      <div>
        <input type="text" v-model="search.operationUserName" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>集配ID</div>
      <div>
        <input type="text" v-model="search.wreqId" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>ユーザーID</div>
      <div>
        <input type="text" v-model="search.userId" @change="setHaveSearchUserId()" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>登録名</div>
      <div>
        <input type="text" v-model="search.userName" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>法人名/屋号</div>
      <div>
        <input type="text" v-model="search.companyName" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>操作名</div>
      <div>
        <select v-model="search.operationType" @change="setDateList()" style="width:240px;">
          <option v-show="haveSearchUserId" value="">全て</option>
          <option v-for="elem in lovs.operation_type" :value="elem.key">
            {{elem.val}}
          </option>
        </select>
      </div>
    </div>
    <div class="ilblk">
      <div>年月</div>
      <div>
        <select v-model="search.month" @change="setDateList()" style="width:100px;" >
          <option v-for="elem in monthList" :value="elem.format_month">
            {{elem.format_month}}
          </option>
        </select>
      </div>
    </div>
    <div class="ilblk">
      <div>日</div>
      <div>
        <select v-model="search.date" style="width:120px;">
          <option v-for="elem in dateList" :value="elem">
            {{elem ? elem : '選択しない'}}
          </option>
        </select>
      </div>
    </div>
    <div class="ilblk">
      <button class="btn" @click="searchOperationHistories()">
        <i class="fa fa-search"></i>
        検索
      </button>
    </div>
    <div class="ilblk">
      <button class="btn" @click="downloadCsv()" v-show="operationHistories.length > 0">
        CSVダウンロード
      </button>
      <VueJsonToCsv :json-data="jsonData" :csv-title="csvTitle" :labels="csvLabels">
        <button class="dispnone" id="csvDownloadbtn">
        </button>
      </VueJsonToCsv>
    </div>
  </div>

  <div class="list-box">
    <table class="tbl">
      <colgroup>
        <col style="width:30px;">
        <col style="width:30px;">
        <col style="width:20px;">
        <col style="width:20px;">
        <col style="width:30px;">
        <col style="width:40px;">
        <col style="width:50px;">
        <col style="width:20px;">
        <col style="width:30px;">
        <col style="width:20px;">
        <col style="width:20px;">
        <col style="width:20px;">
        <col style="width:20px;">
        <col style="width:20px;">
        <col style="width:20px;">
        <col style="width:20px;">
      </colgroup>
      <div>件数:{{operationHistories.length}}</div>
      <tr>
        <th>操作日</th>
        <th>操作者</th>
        <th>集配ID</th>
        <th>ユーザーID</th>
        <th>登録名</th>
        <th>法人名/屋号</th>
        <th>操作名</th>
        <th>月個数</th>
        <th>プラン</th>
        <th>サイズ</th>
        <th>工数</th>
        <th>個数</th>
        <th>残個数</th>
        <th>支払い額</th>
        <th>支払い方法</th>
        <th>備考</th>
      </tr>
      <tr v-for="(history, idx) in operationHistories">
        <td>{{history.operation_date}}</td>
        <td>{{history.operation_user_name}}</td>
        <td>{{history.wreq_id}}</td>
        <td>{{history.user_id}}</td>
        <td>{{history.user_name}}</td>
        <td>{{history.company_name}}</td>
        <td>{{history.operation_name}}</td>
        <td>{{history.monthly_count}}</td>
        <td>{{history.plan}}</td>
        <td>{{history.size}}</td>
        <td>{{history.cost}}</td>
        <td>{{history.count}}</td>
        <td>{{history.available_request_count}}</td>
        <td>{{history.price}}</td>
        <td>{{history.payment_method}}</td>
        <td>
          <span v-if="history.bikou">有り</span>
          <span v-else>無し</span>
          <button class="btn btn_sm bg_blue fr"
            @click="openManagementNotesModal(history)">
            編集
          </button>
        </td>
      </tr>
    </table>
  </div>

  <loading
    v-if="showLoadingModal"
    title="検索中">
  </loading>

  <modal
    val="operationHistoryInfo"
    v-if="showManagementNotesModal"
    title="備考"
    bottomCloseButtonLabel="更新"
    @close="updateManagementNotes()"
    @dismiss="showManagementNotesModal = false">
    <div slot="body">
      <p><textarea type="text" class="form_basic" v-model="operationHistoryInfo.bikou"></textarea></p>
      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
      </div>
    </div>
  </modal>

</div>
</template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import adminOperationHistoryApi from '@/api/adminOperationHistory'
import VueJsonToCsv from "vue-json-to-csv";
import dateMixin from '@/mixin/dateMixin'

export default {
  components: {
    VueJsonToCsv,
  },
  name: 'admin-operation-histories',
  mixins: [dateMixin],
  props: {
    searchPrm: {
      type: Object,
      'default': () => ({
        operationDate: '',
        operationUserName: '',
        wreqId: '',
        userId: '',
        userName: '',
        companyName: '',
        operationType: '1',
        month: '',
        date: '',
      })
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      search: {
        operationDate: '',
        operationUserName: '',
        wreqId: '',
        userId: '',
        userName: '',
        companyName: '',
        operationType: '1',
        month: '',
        date: '',
      },

      lovs: {},
      planMap: {},
      paymentMethodMap: {},
      operationTypeMap: {},
      sizeMap: {},
      operationHistories: [],
      monthList: [],
      dateList: [],

      showManagementNotesModal: false,
      showLoadingModal: false,
      operationHistoryInfo: {},

      jsonData: [],
      csvData: [],
      csvLabels: {},
      csvTitle: '',

      roleType: 0,
      serverErrors: [],
    }
  },
  computed: {
    haveSearchUserId() {
      return !this.search.userId == ''
    },
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 種別なしのスタッフはアクセス不可
    if (this.adminType === 'staff' && this.roleType === 0) {
      this.$router.push({ name: 'staff-top' })
    }
    window.master.$promise.then(mst => {
      this.lovs = mst.lovs
      this.planMap = mst.planMap
      this.paymentMethodMap = mst.lovsMap.payment_method
      this.operationTypeMap = mst.lovsMap.operation_type
      this.sizeMap = mst.lovsMap.size
      this.makeMonthList()
      // this.setSearchPrm()
      this.searchOperationHistories()
    })
  },
  methods: {
    setHaveSearchUserId() {
      if (!this.haveSearchUserId && this.search.operationType == '') {
        this.search.operationType = '1'
      }
    },
    convOperationHistory(history) {
      const ret = Object.assign({}, history)
      const dtFormat = Vue.filter('dtFormat')

      ret.operation_date = dtFormat(history.created_at, 'yyyy/mm/dd HH:MM')
      ret.month = dtFormat(history.created_at, 'yyyy/mm')
      ret.operation_user_name = this.convOperationUserName(history.operation_user_id, history.operation_user, history.user)
      ret.user_name = this.convUserName(history.user)
      ret.company_name = this.convCompanyName(history.user)
      ret.operation_name = this.convOperationType(history.operation_type)
      ret.plan = this.convPlan(history.plan, history.operation_type, history.disp_plan)
      ret.size = this.convSize(history.plan)
      ret.monthly_count = this.convMonthlyCount(history.plan, history.operation_type)
      ret.payment_method = this.convPaymentMethod(history.payment_method_id)
      if (history.operation_type == 27 || history.operation_type == 28) {
        ret.id = 0
      }
      if (history.price != null) {
        ret.price = history.price.toLocaleString()
      }
      if (history.operation_type == 31) {
        if (history.price_diff != null) {
          if (history.price_diff > 0) {
            ret.price = '+' + history.price_diff.toLocaleString()
          } else if (history.price_diff == 0) {
            ret.price = history.price_diff
          } else {
            ret.price = history.price_diff.toLocaleString()
          }
        } else {
          ret.price = null
        }
      }

      return ret
    },
    convOperationUserName(operation_user_id, operation_user, user) {
      if (!user) {
        return ''
      }
      if (operation_user_id != 0 && !operation_user) {
        return ''
      }

      let operationUserName = ''
      if (operation_user_id == 0) {
        operationUserName = 'システム'
      } else {
        operationUserName = operation_user.name1 + operation_user.name2
      }

      return operationUserName
    },
    convUserName(user) {
      if (!user) {
        return ''
      }
      return user.name1 + user.name2
    },
    convCompanyName(user) {
      if (!user) {
        return ''
      }
      return user.company_name
    },
    convOperationType(operationType) {
      const obj = this.operationTypeMap[operationType] || {}
      return obj.val
    },
    convPlan(plan, operationType, dispPlan) {
      if (!plan || (['36', '37'].includes(operationType) && !dispPlan)) {
        return ''
      }
      if (['36', '37'].includes(operationType) && dispPlan) {
        plan = dispPlan
      }
      const planInfos = plan.plan_id.split('_')
      let planStr = ''
      if (planInfos[1] == 1) {
        planStr = '受渡し'
      } else {
        planStr = '不在集配'
      }
      if (planInfos.length > 2 && planInfos[2] == 1) {
        planStr = planStr +  '(集配同時)'
      }

      return planStr
    },
    convSize(plan) {
      if (!plan) {
        return ''
      }
      const planInfos = plan.plan_id.split('_')
      if (planInfos[0] == 'tx') {
        return '単発（45L）'
      } else {
        const obj = this.sizeMap[plan.size] || {}
        return obj.val
      }
    },
    convMonthlyCount(plan, operationType) {
      if (!plan || ['36', '37'].includes(operationType)) {
        return ''
      }
      return plan.wash_count
    },
    convPaymentMethod(payment_method_id) {
      const obj = this.paymentMethodMap[payment_method_id] || {}
      return obj.val
    },
    filterBySearchParams(history) {
      const dtFormat = Vue.filter('dtFormat')
      const search = this.search
      if (search.operationDate) {
        let dt = new Date(history.operation_date)
        if (dtFormat(dt, 'yyyy/mm/dd') != search.operationDate) { return false }
      }
      if (search.operationUserName) {
        const operationUserNameStr = history.operation_user_name
        const reg = new RegExp(search.operationUserName)
        if (!operationUserNameStr.match(reg)) { return false }
      }
      if (search.wreqId) {
        if (!history.wreq_id) { return false }
        if (history.wreq_id.toString() !== search.wreqId) { return false }
      }
      if (search.userId) {
        if (history.user_id.toString() !== search.userId) { return false }
      }
      if (search.userName) {
        const userNameStr = history.user_name
        const reg = new RegExp(search.userName)
        if (!userNameStr.match(reg)) { return false }
      }
      if (search.companyName) {
        if (!history.company_name) { return false }
        const companyNameStr = history.company_name
        const reg = new RegExp(search.companyName)
        if (!companyNameStr.match(reg)) { return false }
      }
      if (search.operationType) {
        if (parseInt(history.operation_type) !== parseInt(search.operationType)) { return false }
      }
      return true
    },
    searchOperationHistories() {
      const dtFormat = Vue.filter('dtFormat')
      const obj = {
        ['month']: dtFormat(this.search.month, 'yyyy/mm'),
        ['date']: this.search.date,
        ['user_id']: this.search.userId,
        ['operation_type']: this.search.operationType
      }
      this.showLoadingModal = true
      adminOperationHistoryApi.index(obj).then(({ data }) => {
        this.operationHistories = data
          .map(e => this.convOperationHistory(e))
          .filter(e => this.filterBySearchParams(e))
        this.showLoadingModal = false
      }).catch(e => {
        this.showLoadingModal = false
      })
    },
    setSearchPrm() {
      this.search = this.searchPrm
    },
    makeMonthList() {
      const dtFormat = Vue.filter('dtFormat')
      let first_month = new Date('2022/12/01')
      let this_month = new Date()
      this_month.setDate(1)
      this.search.month = dtFormat(this_month, 'yyyy/mm')
      this.dateList = this.getDateList(this_month.getFullYear(), this_month.getMonth() + 1)
      this.dateList = ['', ...this.dateList ]

      for (let num = 0; num < 3; num++) {
        if (this_month > first_month) {
          let info = {
            month: new Date(this_month),
            format_month: dtFormat(this_month, 'yyyy/mm'),
          }
          this.monthList.push(info)
          this_month.setMonth(this_month.getMonth() - 1);
          this_month.setDate(1)
        }
      }
    },
    setDateList() {
      if (!this.search.operationType || ['27', '28'].includes(this.search.operationType)) {
        this.dateList = ['']
        this.search.date = ''
        return
      }
      let month = this.monthList.filter(e => this.search.month === e.format_month)
      if (month.length === 0) return
      this.dateList = this.getDateList(month[0].month.getFullYear(), month[0].month.getMonth() + 1)
      this.dateList = ['', ...this.dateList ]
    },
    openManagementNotesModal(history) {
      this.operationHistoryInfo = history
      this.showManagementNotesModal = true
    },
    updateManagementNotes() {
      this.serverErrors = []

      const obj = {
        ['bikou']: this.operationHistoryInfo.bikou,
        ['user_id']: this.operationHistoryInfo.user_id,
        ['operation_type']: this.operationHistoryInfo.operation_type.toString(),
        ['month']: this.operationHistoryInfo.month,
      }
      adminOperationHistoryApi.updatebikous(this.operationHistoryInfo.id, obj).then(() => {
        this.operationHistoryInfo = {}
        this.showManagementNotesModal = false
      }).catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    downloadCsv() {
      this.csvData = []
      const dtFormat = Vue.filter('dtFormat')
      this.csvLabels = {
        operation_date: { title: '操作日' },
        operation_user_name: { title: '操作者' },
        wreq_id: { title: '集配ID' },
        user_id: { title: 'ユーザーID' },
        user_name: { title: '登録名' },
        company_name: { title: '会社/店舗名' },
        operation_name: { title: '操作名' },
        monthly_count: { title: '月回数' },
        plan: { title: 'プラン' },
        size: { title: 'サイズ' },
        cost: { title: '工数' },
        count: { title: '個数' },
        available_request_count: { title: '残個数' },
        price: { title: '支払い額' },
        payment_method: { title: '支払い方法' },
        bikou: { title: '備考' },
      }
      this.makeCsvTitle()
      Object.keys(this.operationHistories).forEach(e => {
        let record = this.operationHistories[e]
        let pushData = {}
        Object.keys(this.csvLabels).forEach(lk => {
          pushData[lk] = ''
        })
        Object.keys(record).forEach(e2 => {
          if (record[e2] == '0') {
            pushData[e2] = '0'
          } else if (!record[e2]) {
            pushData[e2] = ''
          } else {
            pushData[e2] = record[e2];
          }
        })
        this.csvData.push(pushData);
        pushData = null
      });

      //ダウンロード
      this.jsonData = this.csvData
      setTimeout(() => {
        document.getElementById('csvDownloadbtn').click();
      }, 1000)
    },
    makeCsvTitle() {
      // 操作名設定
      let title_operation_name = '';
      if (this.search.operationType) {
        this.lovs.operation_type.forEach(e => {
          if (e.key === this.search.operationType) {
            title_operation_name = e.val
          }
        })
      } else {
        title_operation_name = '全て（ユーザーID：' + this.search.userId + '）'
      }

      // 年月設定
      let title_month = this.search.month.replace('/', '');

      // 日設定
      let title_date = ''
      if (this.search.date) {
        let num = this.search.date
        title_date = ('0' + num ).slice(-2);
      }

      this.csvTitle = '操作履歴_' + title_operation_name + '_' + title_month + title_date
    }
  }
}
</script>

<style lang="scss" scoped>
.contents {
  width: 95%;
  margin: 20px auto 0;
  overflow-y: scroll;
  min-height: 400px;
}
.ilblk {
  display: inline-block;
}
.form-control {
  height: 24px;
  font-size: 12px;
  width: 96%;
}
.form-control-sm {
  font-size: 12px;
}
.btn {
  font-size: 16px;
  border-radius: 4px;
  background-color: #00a9ca;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
}
.btn_sm {
  font-size: 12px;
  padding: 2px 6px;
}
.btn.bg_white {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
}
.btn.bg_danger {
  background-color: #d80a0a;
}
.fr {
  float: right;
}
.search-box {
  width: 1700px;

  .btn{
    margin-left: 20px;
  }
}
.list-box {
  margin: 20px 0 0 0;

  .tbl {
    table-layout: fixed;
    font-size: 14px;
    white-space: nowrap;
    width: 2000px;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #aaa;
    padding: 2px 4px;
  }
}
.ilblk {
  display: inline-block;
}
.search-box {
  width: 1700px;

  .btn {
    margin-left: 20px;
  }
}
</style>
