import { reactive, computed } from 'vue'
import paidApi from '@/api/paid'

const PAID_API_ERROR_CODE_ACCOUNT_NOT_FOUND = 'CE-0001'

function getInitialState() {
  return {
    frdKey: '',
    isAccountStatusLoaded: false,
    hasValidAccount: false,
    canCreateAccount: false,
    accountStatusErrors: [],

  }
}

function convertErrorMessage(errors) {
  // もともとのメッセージが
  // * 該当の貴社顧客はPaid審査中です
  // * 該当の貴社顧客はPaid審査で否決されています
  // * 該当の貴社顧客はPaid利用不可状態です
  // などとなっていてこのまま画面表示しても意味不明なので変換する
  return errors.map(errorObj => {
    const errorMessage = errorObj.error_message
      .replace(/該当の貴社顧客/, 'このアカウント')
      .replace(/貴社/, '') // 出たら気持ち悪いのでともかく消す
    return {
      ...errorObj,
      error_message: errorMessage,
    }
  })
}

export const usePaidPaymentMethod = ({ dummyPriceForAccountStatusCheck }) => {
  const state = reactive(getInitialState())

  const isAccountReady = computed(() => {
    return state.isAccountStatusLoaded && state.hasValidAccount
  })

  const loadAccountStatus = async () => {
    if (!state.frdKey) {
      return new Promise((resolve, reject) => {
        window._paid.getFRDKey(async frdKey => {
          state.frdKey = frdKey
          resolve(_loadAccountStatus())
        })
      })
    } else {
      return _loadAccountStatus()
    }
  }
  const _loadAccountStatus = async () => {
    // 受注可否APIを叩いて結果を見る.
    // アカウント状況の確認をするためには会員ステータス確認APIか受注可否APIのどちらかを利用できそうだが、
    // 先方の資料では受注可否APIを使うように書いてあったので、詳しい理由は不明だが従っておく.
    // (会員ステータス確認APIだと戻り値に日本語メッセージが含まれていなくて使いづらいというのはあるかもしれない)
    const { data: result } = await paidApi.getAccountOrderableStatus({
      frd_key: state.frdKey,
      // 何かしら金額を送らなくてはならないが、細かい金額の差異でAPIの結果が変わることはないだろうから、
      // 呼び出し側で適当にありそうな値を指定する.
      price: dummyPriceForAccountStatusCheck,
    })
    state.isAccountStatusLoaded = true
    state.hasValidAccount = result.is_ok

    // 単にこのユーザーでまだPaidアカウントを作っていない場合と、アカウントは作ったが拒否されたりしてエラーになっている場合とを分けたい.
    const errors = result.errors || []
    state.canCreateAccount = errors.some(e => e.error_code === PAID_API_ERROR_CODE_ACCOUNT_NOT_FOUND)
    state.accountStatusErrors = convertErrorMessage(errors.filter(e => e.error_code !== PAID_API_ERROR_CODE_ACCOUNT_NOT_FOUND))
  }

  const createAccount = async () => {
    const { data: result } = await paidApi.createAccount({
      frd_key: state.frdKey,
    })
    if (result.is_ok) {
      state.hasValidAccount = true
      state.canCreateAccount = false
      state.accountStatusErrors = []
      return true
    } else {
      state.hasValidAccount = false
      state.canCreateAccount = false
      state.accountStatusErrors = [
        { error_message: `アカウント作成に失敗しました。エラーコード: ${result.errors.map(e => e.error_code).join(', ')}` },
      ]
      return false
    }
  }

  return {
    state,
    isAccountReady,
    loadAccountStatus,
    createAccount,
  }
}
