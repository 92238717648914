import { inject, provide, reactive } from 'vue'

const injectionKey = 'user.useUserErrorState'

function getInitialState() {
  return {
    errors: {},
  }
}

export const provideUserErrorState = () => {
  const state = reactive(getInitialState())
  provide(injectionKey, state)
  return state
}

export const useUserErrorState = () => {
  const userErrorState = inject(injectionKey)
  if (!userErrorState) {
    throw new Error('useUserErrorState() is called without provider.')
  }
  return { userErrorState }
}
