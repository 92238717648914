<template>
<div>
<!-- ここから曜日固定入力フォーム -->
  <div class="contents_box" v-show="isStep1">
    <table class="form_tab form_line_top">
      <tr>
        <th><p class="item2">サイズと個数</p></th>
        <td class="size_and_quantity">
          <p>{{size}}サイズ</p>
          <select v-model="count" class="count">
            <option v-for="elem in counts" v-bind:value="elem">
              {{elem}}個
            </option>
          </select>
        </td>
      </tr>
      <tr v-for="(form, index) in forms" :key="form.id" v-bind="form">
        <th class="form_line_bt"><p class="item2">曜日固定</p></th>
        <td class="form_line_bt">
          <div class="delete_form">
            <button @click="deleteForm(index)" v-show="deleteForms[index]">×</button>
          </div>
          <div class="form_pickup">
            <p class="pc_br_none tab_br_none sp_br_yes">集荷：</p>
            <div class="day_of_the_week">
              <label v-for="(w, w_index) in week"><input type="radio" v-model="pickupWeeks[index]" v-bind:name="`pickup_week${form.id}`" v-bind:value="w_index+1" v-bind="form"><span>{{ w }}</span></label>
            </div>
            <div class="pickup_time">
              <select v-show="!areaInfoSameTime" v-model="pickupHourList[index]" @change="setPickupMinutes(index)" class="w55">
                <option v-for="elem in convHours" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span class="ml20" v-show="areaInfoSameTime">{{pickupHourListView[index]}}</span>
              ：
              <select v-show="!areaInfoSameTime" v-model="pickupMinuteList[index]" class="w55">
                <option v-for="elem in pickupMinutesSlcts[index]" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span v-show="areaInfoSameTime">{{pickupMinuteListView[index]}}</span>
            </div>
          </div>
          <div class="form_delivery">
            <p class="pc_br_none tab_br_none sp_br_yes">配達：</p>
            <p v-if="pdSameTime">
              <span class="font_orange">次回集荷時に併せて配達します。</span>
            </p>
            <div v-if="!pdSameTime" class="day_of_the_week">
              <label v-for="(w, w_index) in week"><input type="radio" v-model="deliveryWeeks[index]" v-bind:name="`delivery_week${form.id}`" v-bind:value="w_index+1" v-bind="form"><span>{{ w }}</span></label>
            </div>
            <div v-if="!pdSameTime" class="delivery_time">
              <select v-show="!areaInfoSameTime" v-model="deliveryHourList[index]" @change="setDeliveryMinutes(index)" class="w55">
                <option v-for="elem in convHours" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span class="ml20" v-show="areaInfoSameTime">{{deliveryHourListView[index]}}</span>
              ：
              <select v-show="!areaInfoSameTime" v-model="deliveryMinuteList[index]" class="w55">
                <option v-for="elem in deliveryMinutesSlcts[index]" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span v-show="areaInfoSameTime">{{deliveryMinuteListView[index]}}</span>
            </div>
          </div>
          <span class="font_orange">{{areaInfo.bikou}}</span>
        </td>
      </tr>
      <tr v-if="formCounter === 0">
        <th class="form_line_bt"><p class="item2">曜日固定</p></th>
        <td class="form_line_bt">
          <div class="form-message">
            <p class="font_orange">曜日固定設定を解除します。よろしければ確認画面から確定してください。</p>
          </div>
        </td>
      </tr>
      <div class="add_form">
        <button v-if="formCounter < 6" @click="addForm()">＋追加する</button>
      </div>
      <tr>
        <th>
          <p class="item2">宅配ボックス/パスワード</p>
        </th>
        <td>
          <p>
            <input type="text" v-model="deliveryBoxPw"
              class="form_basic">
          </p>
          <p class="font_orange mg10">※宅配ボックスによる集荷を希望する方のみ記載してください</p>
        </td>
      </tr>

      <tr>
        <th><p class="item2">備考</p></th>
        <td>
          <p>
            <textarea type="text" name="message" id="message"
              v-model="bikou" maxlength="500"
              size="20" class="form_basic" /></textarea>
          </p>
        </td>
      </tr>

      <tr>
        <th class="form_line_l"></th>
        <td class="form_line_r">
          <wash-request-week-notice-messages>
          </wash-request-week-notice-messages>
        </td>
      </tr>
    </table>
    <div class="err-msg-area">
      <span class="font_orange" v-show="showErrMsg1">
        曜日を選択してください
      </span>
      <!-- <span class="font_orange" v-show="noAvailableRequestCount">
        残り依頼個数が0です
      </span>
      <span class="font_orange" v-show="showErrMsg1">
        日付が間違っています
      </span>
      <span class="font_orange" v-show="showErrMsg2">
        集荷時間は現在より5時間後以降を指定してください
      </span> -->
      <span class="font_orange" v-show="showErrMsg2">
        配達時間は集荷時間から48時間後以降を指定してください
      </span>
      <!-- <span class="font_orange" v-show="showErrMsg4">
        配達時間は現在より30日以内を指定してください
      </span> -->
      <!-- <span class="font_orange" v-show="showErrMsg5">
        集配同時プランの当日の集配依頼は11時までです
      </span> -->
      <span class="font_orange" v-show="showErrMsg3">
        集配設定が重複しています。
      </span>
      <span class="font_orange" v-show="showErrMsg4">
        提供エリア外のためご利用いただけません
      </span>
      <span class="font_orange" v-show="showErrMsg5">
        ご登録のエリアでは設定できない時間が含まれています
      </span>
      <span class="font_orange" v-show="showErrMsg6">
        曜日固定の日数に対して残個数が{{notEnoughCount}}個不足しています。追加購入を行なったうえで、あらためて曜日固定の設定を行なってください。
      </span>
    </div>
      <p class="submit_bx1">
        <button @click="nextStep()" class="submit1 bg_green step1do" :disabled="role == 200">
          確認画面
        </button>
      </p>
  </div>
<!-- ここまで曜日固定入力フォーム（エラーメッセージも含む） -->

<!-- ここから曜日固定確認画面 -->
  <div class="contents_box" v-show="isStep2">
    <p v-if="formCounter !== 0" class="mypage_notes mg30 mg_bt30 font_orange">
      下記の日時でよろしければ送信してください。
    </p>
    <p v-else class="mypage_notes mg30 mg_bt30 font_orange">
      曜日固定設定をすべて解除します。よろしければ送信してください。
    </p>
    <table v-if="formCounter !== 0" class="form_tab form_line_top">
      <tr>
        <th><p class="item2">サイズと個数</p></th>
        <td class="size_and_quantity">
          <p>{{size}}サイズ</p>
          <p>{{ count }}個</p>
        </td>
      </tr>
      <tr v-for="n of formCounter" :key="n">
        <th class="form_line_l"><p class="item2">曜日固定</p></th>
        <td class="form_line_r">
          <div>
            <div class="pickup_day_time">
              <div class="pickup_day_time_item">
                <p>集荷：</p>
                <p>{{ week[pickupWeeks[n -1] -1] }}曜</p>
              </div>
              <div class="pickup_day_time_item">
                <p>時間：</p>
                <p>{{ pickupTimes[n -1] }}</p>
              </div>
            </div>
            <div v-if="pdSameTime" class="delivery_day_next">
              <p>配達：</p>
              <p class="font_orange">次回集荷時に併せて配達します。</p>
            </div>
            <div v-else class="delivery_day_time">
              <div class="delivery_day_time_item">
                <p>配達：</p>
                <p>{{ week[deliveryWeeks[n -1] -1] }}曜</p>
              </div>
              <div class="delivery_day_time_item">
                <p>時間：</p>
                <p>{{ deliveryTimes[n -1] }}</p>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th><p class="item2">宅配ボックス/パスワード</p></th>
        <td><p>{{deliveryBoxPw}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">備考</p></th>
        <td><p v-html="$options.filters.lineBreakToBR(bikou)"></p></td>
      </tr>
    </table>
    <div class="err-msg-area">
      <p><span class="font_orange" v-show="showErrMsg1">
        曜日を選択してください
      </span></p>
      <!-- <p><span class="font_orange" v-show="noAvailableRequestCount">
        残り依頼個数が0です
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg1">
        日付が間違っています
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg2">
        集荷時間は現在より5時間後以降を指定してください
      </span></p> -->
      <p><span class="font_orange" v-show="showErrMsg2">
        配達時間は集荷時間から48時間後以降を指定してください
      </span></p>
      <!-- <p><span class="font_orange" v-show="showErrMsg4">
        配達時間は現在より30日以内を指定してください
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg5">
        集配同時プランの当日の集配依頼は11時までです
      </span></p> -->
      <p><span class="font_orange" v-show="showErrMsg3">
        集配設定が重複しています。
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg4">
        提供エリア外のためご利用いただけません
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg5">
        ご登録のエリアでは設定できない時間が含まれています
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg6">
        曜日固定の日数に対して残個数が{{notEnoughCount}}個不足しています。追加購入を行なったうえで、あらためて曜日固定の設定を行なってください。
      </span></p>
      <p><span class="font_orange" v-show="isStatusDisabled">
        決済情報が確認できません
      </span></p>
      <p><span class="font_orange" v-show="isStatusWithdrawn">
        このアカウントは退会済みです
      </span></p>
      <p><span class="font_orange" v-show="isStatusStoppedCredit">
        クレジットカード情報を更新してください
      </span></p>
      <p><span class="font_orange" v-show="isStatusStoppedOther">
        お支払い情報を確認してください
      </span></p>
      <!-- <p><span class="font_orange" v-show="isWreqCountLacking">
        残り依頼個数が足りません
      </span></p> -->
      <!-- <p><span class="font_orange" v-show="isPreqCountMax">
        申し訳ございません。予約がいっぱいのため翌日以降を選択ください。
      </span></p> -->
    </div>
    <p class="submit_bx_l">
      <button @click="prevStep()" class="submit1 bg_gray">
        戻る
      </button>
    </p>
    <p class="submit_bx_r">
      <button @click="addWashRequest()" class="submit1 bg_green">
        OK
      </button>
    </p>
  </div>
<!-- ここまで曜日固定確認画面（エラーメッセージも含む） -->

<!-- ここから曜日固定登録完了画面 -->
  <div class="contents_box" v-show="isStep3">
    <p v-if="formCounter !== 0" class="mypage_notes mg40 mg_bt40 font_orange">
      ありがとうございました。<br>
      集配のご依頼を承りました。
    </p>
    <p v-else class="mypage_notes mg40 mg_bt40 font_orange">
      ありがとうございました。<br>
      曜日固定設定を解除しました。
    </p>
    <p v-if="formCounter !== 0" class="mypage_notes mg40">
      ご登録のメールアドレスに確認のメールを送信しました。<br>
      <a href="https://awesome-wash.com/agreement/" target="_blank">「集配に関する注意事項」</a>をご確認の上、洗濯物の準備をお願いいたします。
    </p>
    <p class="submit_bx1">
      <router-link :to="{name:'wash-request-currents'}" class="glnavi_btn_on">
        集配内容を確認
      </router-link>
    </p>
    <p class="mypage_notes mg40">
      <a href="https://awesome-wash.com/friend/" target="_blank">
        <picture>
          <source media="(min-width: 641px)" srcset="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand.jpg">
          <source media="(max-width: 640px)" srcset="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand_sp.jpg">
          <img src="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand.jpg" alt="新しいお洗濯のカタチを友達にシェアしよう　紹介した方も 申込んだ方も　スターバックスカード最大1,000円分をもらえる！" style="width:100%">
        </picture>
      </a>
    </p>
  </div>
<!-- ここまで曜日固定登録完了画面（エラーメッセージも含む） -->

</div>
</template>

<script>
import Vue from 'vue'
import userApi from '@/api/user'
import areaInfoApi from '@/api/areaInfo'
import dateMixin from '@/mixin/dateMixin'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'wash-request',
  data() {
    return {
      isHaveWreq: false,
      forms:[
        {id:0,name:0}
      ],
      lovs: {},
      lovsMap: {},
      formCounter: 1,
      currentStep: 1,
      maxStep: 3,
      isStep1: true,
      isStep2: false,
      isStep3: false,
      isActive1: true,
      isActive2: false,
      deleteForms: [],
      currentPlan: {},
      pdSameTime: false,
      hours: window.VueHourOptions,
      minutes: this.setMinuteOption([0,30]),
      minutes2: this.setMinuteOption([0]),
      pickupMinutes: this.setMinuteOption([0,30]),
      pickupMinutesSlcts: [],
      deliveryMinutes: this.setMinuteOption([0,30]),
      deliveryMinutesSlcts: [],
      convHours: window.VueHourOptions,
      areaInfo: [],
      sHour: 10,
      sMinute: 0,
      eHour: 10,
      eMinute: 0,
      areaInfoSameTime: false,

      counts: [
        1,2,3,4,5
      ],
      week: [],
      pickupHourList: [],
      pickupMinuteList: [],
      pickupHourListView: [],
      pickupMinuteListView: [],
      pickupHour: 12,
      pickupMinute: 0,
      pickupTimes: [],
      pickupWeeks: [],
      deliveryHourList: [],
      deliveryMinuteList: [],
      deliveryHourListView: [],
      deliveryMinuteListView: [],
      deliveryHour: 12,
      deliveryMinute: 0,
      deliveryTimes: [],
      deliveryWeeks: [],
      count: 1,
      deliveryBoxPw: '',
      // Business Rule No.20 備考欄は最大で全角500文字まで入力可能
      bikou: '',
      businessHolidays: [],
      showErrMsg1: false,
      showErrMsg2: false,
      showErrMsg3: false,
      showErrMsg4: false,
      showErrMsg5: false,
      showErrMsg6: false,
      notEnoughCount: 0,
      isWaitingResponse: false,
      // isDupRequest: false,
      isStatusDisabled: false,
      isStatusWithdrawn: false,
      isStatusStoppedCredit: false,
      isStatusStoppedOther: false,
      // isWreqCountLacking: false,
      // isPreqCountMax: false,
      lovs: [],
      size: '',

    }
  },
  computed: {
    ...mapState('user', {
      isSaturdayAvailable: state => state.is_saturday_available,
      role: state => state.role,
      zipcode: state => state.send_addr_zipcode,
    }),
    currentMonth() {
      return (new Date()).getMonth() + 1
    },
    // noAvailableRequestCount() {
    //   return this.availableRequestCount <= 0
    // },
  },
  async mounted() {
    // lovsテーブル取得
    const mst = await window.master.$promise
    this.lovs = mst.lovs
    this.lovsMap = mst.lovsMap

    const week = this.lovs.week
    week.forEach(e => {
      this.week.push(e.val)
    })

    // 休業日取得
    this.businessHolidays = await this.getBusinessHolidays()

    const obj = {
      'zipcode': this.zipcode,
      'is_onetime': 0
    }
    areaInfoApi.getAreaInfo(obj).then(({ data }) => {
      this.areaInfo = data

      if (!data['error']) {
        let sTimes = this.areaInfo.start_time.split(':')
        this.sHour = sTimes[0]
        this.sMinute = sTimes[1] == 0 ? 0 : sTimes[1]
        let eTimes = this.areaInfo.end_time.split(':')
        this.eHour = eTimes[0]
        this.eMinute = eTimes[1] == 0 ? 0 : eTimes[1]
      // } else {
      //   this.sMinute = '00'
      }

      if (this.sHour ==this.eHour && this.sMinute == this.eMinute) {
        this.areaInfoSameTime = true
        // if (this.sMinute == 0) {
        //   this.sMinute = "00"
        // }
      }

      let convHoursRe = []
      this.convHours.forEach(v => {
        if (v.val >=  this.sHour && v.val <= this.eHour) {
          convHoursRe.push(v);
        }
      })
      this.convHours = convHoursRe

      // Business Rule No.2 集荷時間のデフォルトは現在プラス5時間
      let pickupDt = new Date(new Date().valueOf() + 3600*5*1000)
      // 分に余りがあったら時間を進める
      if (pickupDt.getMinutes() > 0) {
        pickupDt = new Date(pickupDt.valueOf() + 3600*1*1000)
      }
      // 選べる時間が18時以降とかなので、それより前だったらそこまで進める
      // →エリア情報の選択可能な時間で比較する
      const pickupHour = pickupDt.getHours()
      const hourDiff = this.sHour - pickupHour
      if (hourDiff > 0) {
        pickupDt = new Date(pickupDt.valueOf() + 3600*hourDiff*1000)
      }
      pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailable)  // 休業日考慮
      this.pickupHour = pickupDt.getHours()
      if (pickupDt.getHours() > this.convHours[this.convHours.length -1].val) {
        this.pickupHour = this.convHours[this.convHours.length -1].val
      }
      this.pickupMinute = this.sMinute // ユーザーが混乱しそうだから00分でよいだろう→0が選択できない可能性もあるため開始の分にする
      this.pickupHourList.push(this.pickupHour)
      this.pickupMinuteList.push(this.pickupMinute)
      this.pickupWeeks.push(null)
      this.pickupMinutesSlcts.push(this.pickupMinutes)

      // Business Rule No.3 配達時間のデフォルトは集荷時間の48時間後に設定
      let deliveryDt = new Date(pickupDt.valueOf() + 86400*2*1000)
      deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays, this.isSaturdayAvailable)  // 休業日考慮
      // 期間内で休日を除き48時間以上の時間があるか確認
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
      if (workingDay < 3) {
        // 営業日数が２日未満の場合
        const addDate = 3 - workingDay
        deliveryDt.setDate((deliveryDt.getDate() + addDate))
      }
      this.deliveryHour =  deliveryDt.getHours()
      if (deliveryDt.getHours() > this.convHours[this.convHours.length -1].val) {
        this.deliveryHour = this.convHours[this.convHours.length -1].val
      }
      this.deliveryMinute = this.sMinute // ユーザーが混乱しそうだから00分でよいだろう→0が選択できない可能性もあるため開始の分にする
      this.deliveryHourList.push(this.deliveryHour)
      this.deliveryMinuteList.push(this.deliveryMinute)
      this.deliveryWeeks.push(null)
      this.deliveryMinutesSlcts.push(this.deliveryMinutes)
    })

    window.master.$promise.then(mst => {
      userApi.getMyCurrentPlan().then(({ data }) => {
        const planMst = mst.planMap[data.plan_id]
        Object.keys(planMst).forEach(k => {
          data[`mst_${k}`] = planMst[k]
        })
        // Business Rule No.16 ご利用プラン：契約中のプランが表示される
        this.currentPlan = data
        // サイズ取得
        this.size = this.lovsMap.size[data.mst_size].val;

        const planInfos = data.plan_id.split('_')
        if (planInfos.length > 2 && planInfos[2] == 1) {
          this.pdSameTime = true
        } else {
          this.pdSameTime = false
        }
      })
      //ユーザーのリクエスト情報取得
      this.getUserWeekRequests()
    })
  },
  mixins: [dateMixin],
  methods: {
    setMinuteOption(key) {
      if (!key) {
        return window.VueMinuteOptions
      }
      let filteredOptions = window.VueMinuteOptions.filter(option => key.includes(option.val));
      return filteredOptions
    },
    isSelect1() {
      this.isActive1 = true
      this.isActive2 = false
    },
    isSelect2() {
      this.isActive1 = false
      this.isActive2 = true
    },
    addForm () {
      let max = this.forms.reduce(function(a,b) {
        return a > b.id ? a : b.id
      },0)
      this.forms[this.formCounter] = {
        id:max + 1,
        name:max + 1,
      }

      if (this.areaInfoSameTime) {
        this.pickupHourList[this.formCounter] = this.sHour;
        this.pickupMinuteList[this.formCounter] = this.sMinute;
        this.deliveryHourList[this.formCounter] = this.sHour;
        this.deliveryMinuteList[this.formCounter] = this.sMinute;
        this.pickupHourListView[this.formCounter] = this.sHour;
        this.pickupMinuteListView[this.formCounter] = this.sMinute == 0 ? "00" : this.sMinute;
        this.deliveryHourListView[this.formCounter] = this.sHour;
        this.deliveryMinuteListView[this.formCounter] = this.sMinute == 0 ? "00" : this.sMinute;
      } else {
        this.pickupHourList[this.formCounter] = this.pickupHour
        this.pickupMinuteList[this.formCounter] = this.pickupMinute
        this.deliveryHourList[this.formCounter] = this.deliveryHour
        this.deliveryMinuteList[this.formCounter] = this.deliveryMinute
      }

      this.pickupMinutesSlcts[this.formCounter] = this.pickupMinutes
      this.deliveryMinutesSlcts[this.formCounter] = this.deliveryMinutes
      if (this.pdSameTime) {
        this.deliveryWeeks[max + 1] = null
        this.setPickupMinutes(this.formCounter)
      } else {
        this.setDeliveryMinutes(this.formCounter)
      }
      delete this.pickupWeeks[this.formCounter]
      delete this.deliveryWeeks[this.formCounter]

      if (this.formCounter !== 0) {
        this.deleteForms[this.formCounter - 1] = false
      }
      if (this.deleteForms.length !== 0) {
        this.deleteForms[this.formCounter] = true
      } else {
        this.deleteForms[this.formCounter] = true
      }

      this.formCounter = this.formCounter + 1
    },
    deleteForm (index) {
      this.forms.splice(index, 1)
      this.formCounter = this.formCounter - 1

      delete this.pickupHourList[this.formCounter]
      delete this.pickupMinuteList[this.formCounter]
      delete this.deliveryHourList[this.formCounter]
      delete this.deliveryMinuteList[this.formCounter]
      delete this.deliveryMinutesSlcts[this.formCounter]
      delete this.pickupWeeks[this.formCounter]
      delete this.deliveryWeeks[this.formCounter]
      if (this.areaInfoSameTime) {
        delete this.pickupHourListView[this.formCounter]
        delete this.pickupMinuteListView[this.formCounter]
        delete this.deliveryHourListView[this.formCounter]
        delete this.deliveryMinuteListView[this.formCounter]
      }

      this.deleteForms[this.formCounter] = false
      this.deleteForms[this.formCounter - 1] = true
      if (this.formCounter === 1 && !this.isHaveWreq) {
        this.deleteForms[this.formCounter - 1] = false
      }

      if (this.formCounter === 0) {
        this.pickupHourList = []
        this.pickupMinuteList = []
        this.deliveryHourList = []
        this.deliveryMinuteList = []
        this.pickupHourListView = []
        this.pickupMinuteListView = []
        this.deliveryHourListView = []
        this.deliveryMinuteListView = []
        this.pickupWeeks = []
        this.deliveryWeeks = []
      }
    },
    async nextStep() {
      if (!this.checkCurrentStep()) { return }
      // 残個数が不足している場合は設定できない showErrMsg6
      const result = await this.checkWeekRequest()
      if (!result) {
        return
      }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    setPickupMinutes(id) {
      if (this.hours[this.hours.length - 1].val == this.pickupHourList[id]) {
        // 22時を選択した場合、00分まで選択可能とする
        this.pickupMinuteList[id] = this.setMinute(this.pickupMinuteList[id])
        this.pickupMinutesSlcts[id] = this.minutes2
      } else if (this.convHours[this.convHours.length - 1].val === this.pickupMinuteList[id]) {
        // end_timeの分が00分で、その時間が選択された場合は00分まで選択可能とする
        if (this.eMinute == 0) {
          this.pickupMinuteList[id] = this.setMinute(this.pickupMinuteList[id])
          this.pickupMinutesSlcts[id] = this.minutes2
        }
      } else {
        this.pickupMinutesSlcts[id] = this.minutes
      }
    },
    setDeliveryMinutes(id) {
      if (this.hours[this.hours.length - 1].val === this.deliveryHourList[id]) {
        // 22時を選択した場合、00分まで選択可能とする
        this.deliveryMinuteList[id] = this.setMinute(this.deliveryMinuteList[id])
        this.deliveryMinutesSlcts[id] = this.minutes2
      } else if (this.convHours[this.convHours.length - 1].val === this.deliveryMinuteList[id]) {
        // end_timeの分が00分で、その時間が選択された場合は00分まで選択可能とする
        if (this.eMinute == 0) {
          this.deliveryMinuteList[id] = this.setMinute(this.deliveryMinuteList[id])
          this.deliveryMinutesSlcts[id] = this.minutes2
        }
      } else {
        this.deliveryMinutesSlcts[id] = this.minutes
      }
    },
    setMinute(minute) {
      return this.minutes2[this.minutes2.length - 1].val < minute ? 0 : minute
    },
    checkStep1() {
      this.resetLocalErrMsgs()
      this.resetServerErrMsgs()

      // 提供エリア外の場合は依頼不可
      if (this.areaInfo['error']) {
        this.showErrMsg4 = true
        return false
      }

      this.pickupTimes = []
      this.deliveryTimes = []

      for (let i = 0; i < this.formCounter; i++) {
        if (this.areaInfoSameTime) {
          if (this.pdSameTime) {
            if (this.pickupHourListView[i] != this.sHour
              || this.pickupMinuteListView[i] != this.sMinute) {
              this.showErrMsg5 = true
              return false
            }
          } else {
            if (this.pickupHourListView[i] != this.sHour
              || this.pickupMinuteListView[i] != this.sMinute
              || this.deliveryHourListView[i] != this.sHour
              || this.deliveryMinuteListView[i] != this.sMinute) {
              this.showErrMsg5 = true
              return false
            }
          }
        }
        let pHour = ('00' + this.pickupHourList[i]).slice(-2)
        let pMinute = ('00' + this.pickupMinuteList[i]).slice(-2)
        let dHour = ('00' + this.deliveryHourList[i]).slice(-2)
        let dMinute = ('00' + this.deliveryMinuteList[i]).slice(-2)
        this.pickupTimes.push(pHour + ":" + pMinute)
        this.deliveryTimes.push(dHour + ":" + dMinute)
      }

      // let yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      // const pickupDt = new Date(
      //   this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDate,
      //   this.pickupHour, this.pickupMinute, 0)
      // yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      // const deliveryDt = new Date(
      //   this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDate,
      //   this.deliveryHour, this.deliveryMinute, 0)
      // if (isNaN(pickupDt.valueOf()) || isNaN(deliveryDt.valueOf())) {
      //   this.showErrMsg1 = true
      //   return false
      // }
      // 現在より5時間後以降になければエラー
      // let pickupMinDt = new Date(now.valueOf() + 3600*5*1000)
      // if (pickupDt < pickupMinDt) {
      //   this.showErrMsg2 = true
      //   return false
      // }
      // if (this.pdSameTime) {
      //   let nowTime = new Date()
      //   yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      //   let pickupTimeLimit = new Date(
      //     this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDate, 12, 0, 0)
      //   if (nowTime > pickupTimeLimit) {
      //     // 集配同時プランで当日の集配依頼の場合、集配依頼を出せる時間を12時までとする
      //     this.showErrMsg5 = true
      //     return false
      //   }

      //   // 集配同時の場合は配達時間のチェックに引っかからないs
      //   deliveryDt.setFullYear(2099)
      // }
      // Business Rule No.4 配達時間は最短でも集荷時間の48時間後以降しか選べない
      // const minDiff = 86400*2*1000
      // const result = deliveryDt.valueOf() - pickupDt.valueOf() >= minDiff
      // if (!result) {
      //   this.showErrMsg3 = true
      //   return false
      // }

      for (let i = 0; i < this.formCounter; i++) {
        // 曜日未設定で依頼できない showErrMsg1
        let pickupW = this.pickupWeeks[i]
        let deliveryW = this.deliveryWeeks[i]
        if (this.pdSameTime) {
          if (!pickupW) {
            this.showErrMsg1 = true
            return false
          }
        } else {
          if (!pickupW || !deliveryW) {
            this.showErrMsg1 = true
            return false
          }
        }

        // 配達時間は48時間後しか依頼できない showErrMsg2
        if (!this.pdSameTime) {
          let pickupDt = new Date()
          // let addDt = 0;
          // if (pickupDt.getDay() > pickupW) {
          let addDt = pickupW - pickupDt.getDay()
          // } else if (pickupDt.getDay() < pickupW) {
          //   addDt = pickupW - pickupDt.getDay()
          // }
          pickupDt = new Date(pickupDt.valueOf() + 86400*addDt*1000)
          let pickupDtYear = pickupDt.getFullYear();
          let pickupDtMonth = pickupDt.getMonth() + 1;
          let pickupDtDay = pickupDt.getDate();
          pickupDt = new Date(pickupDtYear+"/"+pickupDtMonth+"/"+pickupDtDay+" "+this.pickupTimes[i])
          let deliveryDt = new Date(pickupDt.valueOf())
          let deliveryDtYear = pickupDt.getFullYear();
          let deliveryDtMonth = pickupDt.getMonth() + 1;
          let deliveryDtDay = pickupDt.getDate();
          deliveryDt = new Date(deliveryDtYear+"/"+deliveryDtMonth+"/"+deliveryDtDay+" "+this.deliveryTimes[i])

          if (pickupW > deliveryW) {
            deliveryDt.setDate(deliveryDt.getDate() + 7 - (pickupW - deliveryW));
          } else if (pickupW === deliveryW) {
            deliveryDt.setDate(deliveryDt.getDate() + 7);
          } else {
            deliveryDt.setDate(deliveryDt.getDate() + (deliveryW - pickupW));
          }

          const workingDay = this.getWorkingDay(pickupDt, deliveryDt, [], 1)
          if (workingDay < 3) {
            this.showErrMsg2 = true
            return false
          }
        }

        // 重複した設定はできない showErrMsg3
        for (let j = 0; j < this.formCounter; j++) {
          if (i !== j
            && this.pickupWeeks[j] === this.pickupWeeks[i]
            && this.pickupTimes[j] === this.pickupTimes[i]) {
              if (!this.pdSameTime) {
                if (this.deliveryWeeks[j] === this.deliveryWeeks[i]
                  && this.deliveryTimes[j] === this.deliveryTimes[i]) {
                  this.showErrMsg3 = true
                  return false
                }
              } else {
                this.showErrMsg3 = true
                return false
              }
          }
        }
      }
      // Business Rule No.4.1 配達時間は最長で30日後まで選択可能
      // let deliveryMaxDt = new Date(now.valueOf() + 3600*24*30*1000)
      // if (!this.pdSameTime) {
      //   // 集配同時の場合は除く
      //   if (deliveryDt > deliveryMaxDt) {
      //     this.showErrMsg4 = true
      //     return false
      //   }
      // }
      // this.pickupDt = pickupDt
      // this.deliveryDt = deliveryDt
      return true
    },
    async checkWeekRequest() {
      const data = {
        form_count: this.formCounter,
        pickup_times: this.pickupTimes,
        pickup_weeks: this.pickupWeeks,
        delivery_times: this.deliveryTimes,
        delivery_weeks: this.deliveryWeeks,
        count: this.count,
        pd_same_time: this.pdSameTime,
      }

      try {
        const response = await userApi.checkWeekRequest(data);
        this.isWaitingResponse = false
        this.notEnoughCount = 0
        return true
      } catch (err) {
        this.isWaitingResponse = false
        if (err.response.data.reason === 'not_enough_count') {
          this.notEnoughCount = err.response.data.count
          this.showErrMsg6 = true
        }
        if (err.response.data.reason === 'status_disabled') {
          this.isStatusDisabled = true
        }
        if (err.response.data.reason === 'status_withdrawn') {
          this.isStatusWithdrawn = true
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
        }
        if (err.response.data.reason === 'status_stopped_credit') {
          this.isStatusStoppedCredit = true
        }
        if (err.response.data.reason === 'status_stopped_other') {
          this.isStatusStoppedOther = true
        }
        return false
      }
    },
    checkStep2() {
      return true
    },
    addWashRequest() {
      if (!this.checkStep1()) { return }
      if (this.isWaitingResponse) { return }
      // 念のため...
      // if (this.availableRequestCount <= 0) { return }
      this.isWaitingResponse = true
      this.resetServerErrMsgs()
      const data = {
        form_count: this.formCounter,
        pickup_times: this.pickupTimes,
        pickup_weeks: this.pickupWeeks,
        delivery_times: this.deliveryTimes,
        delivery_weeks: this.deliveryWeeks,
        count: this.count,
        delivery_box_pw: this.deliveryBoxPw,
        bikou: this.bikou,
        pd_same_time: this.pdSameTime,
      }
      userApi.addWeekRequest(data).then(({ data }) => {
        this.isWaitingResponse = false
        this.nextStep()
        this.$store.dispatch(
          'user/getMe',
          {forceRefresh: true}
        )
      }).catch(err => {
        this.isWaitingResponse = false
        // if (err.response.data.reason === 'dup') {
        //   this.isDupRequest = true
        // }
        if (err.response.data.reason === 'status_disabled') {
          this.isStatusDisabled = true
        }
        if (err.response.data.reason === 'status_withdrawn') {
          this.isStatusWithdrawn = true
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
        }
        if (err.response.data.reason === 'status_stopped_credit') {
          this.isStatusStoppedCredit = true
        }
        if (err.response.data.reason === 'status_stopped_other') {
          this.isStatusStoppedOther = true
        }
        // if (err.response.data.reason === 'wreq_count_lacking') {
        //   this.isWreqCountLacking = true
        // }
        // if (err.response.data.reason === 'preq_count_max') {
        //   this.isPreqCountMax = true
        // }
      })
    },
    resetLocalErrMsgs() {
      this.showErrMsg1 = false
      this.showErrMsg2 = false
      this.showErrMsg3 = false
      this.showErrMsg4 = false
      this.showErrMsg5 = false
      this.showErrMsg6 = false
    },
    resetServerErrMsgs() {
      // this.isDupRequest = false
      this.isStatusDisabled = false
      this.isStatusWithdrawn = false
      this.isStatusStoppedCredit = false
      this.isStatusStoppedOther = false
      // this.isWreqCountLacking = false
      // this.isPreqCountMax = false
    },
    getUserWeekRequests() {
      userApi.getUserWeekRequests().then(({ data }) => {
        const userWeekRequests = data
        if (userWeekRequests['week_request_infos']) {
          //フォーム作成
          this.count = userWeekRequests['count']
          this.deliveryBoxPw = userWeekRequests['delivery_box_pw'];
          this.bikou = userWeekRequests['bikou'];

          for (let i = 0; i < userWeekRequests['week_request_infos'].length; i++) {
            if (i !== 0) {
              this.addForm()
            }
            if (this.pdSameTime) {
              this.pickupWeeks[i] = userWeekRequests['week_request_infos'][i]['pickup_week_id'];
              let pDt = userWeekRequests['week_request_infos'][i]['pickup_time'].split(':');
              if (this.areaInfoSameTime) {
                this.pickupHourList[i] = this.sHour;
                this.pickupMinuteList[i] = this.sMinute;
                this.pickupHourListView[i] = pDt[0];
                this.pickupMinuteListView[i] = pDt[1] == 0 ? "00" : pDt[1];
              } else {
                this.pickupHourList[i] = pDt[0];
                this.pickupMinuteList[i] = pDt[1] == 0 ? 0 : pDt[1];
              }
              this.setPickupMinutes(i)
            } else {
              this.pickupWeeks[i] = userWeekRequests['week_request_infos'][i]['pickup_week_id'];
              let pDt = userWeekRequests['week_request_infos'][i]['pickup_time'].split(':');
              this.deliveryWeeks[i] = userWeekRequests['week_request_infos'][i]['delivery_week_id'];
              let dDt = userWeekRequests['week_request_infos'][i]['delivery_time'].split(':');
              if (this.areaInfoSameTime) {
                this.pickupHourList[i] = this.sHour;
                this.pickupMinuteList[i] = this.sMinute;
                this.pickupHourListView[i] = pDt[0];
                this.pickupMinuteListView[i] = pDt[1] == 0 ? "00" : pDt[1];
                this.deliveryHourList[i] = this.sHour;
                this.deliveryMinuteList[i] = this.sMinute;
                this.deliveryHourListView[i] = dDt[0];
                this.deliveryMinuteListView[i] = dDt[1] == 0 ? "00" : dDt[1];
              } else {
                this.pickupHourList[i] = pDt[0];
                this.pickupMinuteList[i] = pDt[1] == 0 ? 0 : pDt[1];
                this.deliveryHourList[i] = dDt[0];
                this.deliveryMinuteList[i] = dDt[1] == 0 ? 0 : dDt[1];
              }
              this.setPickupMinutes(i)
              this.setDeliveryMinutes(i)
            }
          }

          this.isHaveWreq = true
          this.deleteForms[this.formCounter -1] = this.isHaveWreq
        } else {
          if (this.areaInfoSameTime) {
            this.pickupHourListView[this.formCounter -1] = this.sHour;
            this.pickupMinuteListView[this.formCounter -1] = this.sMinute == 0 ? "00" : this.sMinute;
            this.deliveryHourListView[this.formCounter -1] = this.sHour;
            this.deliveryMinuteListView[this.formCounter -1] = this.sMinute == 0 ? "00" : this.sMinute;
          }
          this.isHaveWreq = false
          this.deleteForms[this.formCounter -1] = this.isHaveWreq
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.plan_name_wrap {
  min-height: 30px;
  .plan_name {
    margin-right: 6px;
  }
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
.step1do:disabled {
  opacity: 0.6;
}
table td {
  word-break : break-all;
}
label {
    margin-right: 5px; /* ボタン同士の間隔 */
}
label input {
    display: none;
}
label span {
    padding: 10px 12px;
    border-radius:30px;
    background-color: #eee;
}
label input:checked + span {
    color: #FFF; /* 文字色を白に */
    background: #00a9ca;; /* 背景色を薄い赤に */
}
.day_of_the_week{
  display:flex;
  flex-wrap: wrap;
  margin-top:10px;
  gap: 2px 2px;
}
.form_pickup,.form_delivery{
  display: flex;
  gap: 6px 15px;
  p{
    margin: 10px 0;
  }
}
.form_delivery{
  margin-top: 10px;
}
.size_and_quantity{
  display: flex;
  gap: 6px 15px;
  p{
    margin-top: 6px;
  }
}
.pickup_time {
  margin-top: 5px;
}
// 確認画面
.pickup_day_time,.delivery_day_time{
  display: flex;
  gap: 6px 30px;
}
.pickup_day_time_item,.delivery_day_time_item,.delivery_day_next{
  display: flex;
  gap: 6px 5px;
}
.delivery_day,.delivery_day_next{
    margin-top: 10px;
}
// タイトル
.form_title{
    display:flex;
}
.title_active,.title_inactive{
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 0;
  text-align:center;
  font-size: 16px;
  font-weight: normal;
  clear:both;
}
.title_active{
  background-color: #6AA84F;
  color: #fff;
}
.title_inactive{
  background-color: rgb(182, 215, 168);
  color: #6AA84F;
}
.count{
  width:100px;
}
//入力画面の✗ボタン
.delete_form{
  text-align: right;
}
.delete_form button {
    font-size: 30px;
    background: none;
}
// 入力画面の追加するボタン
.add_form{
  margin-top: 15px;
  margin-bottom: 60px;
}
.btn_add_credit {
  margin: 0px 4px 0px 0px;
  padding: 4px 12px 4px 12px;
  background-color: #00a9ca;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #ffffff;
    filter: alpha(opacity=75);
    -moz-opacity:0.75;
    opacity:0.75;
  }
}
// スマホレスポンシブ
@media only screen and (max-width: 800px) {
.form_pickup,.form_delivery{
  display: block;
  p{
    margin: 0;
  }
}
.pickup_time,.delivery_time{
  margin-top: 15px;
}
.form_delivery{
  margin-top: 20px;
}
}
</style>
