<template>
  <tr>
    <th><p class="item3">お支払い方法</p></th>
    <td>
      <div class="wrap_input">
        <div v-for="elem in onetimePaymentMethods" class="mb4">
          <div class="slc payment-method-row">
            <input
              type="radio"
              :id="`option${elem.key}`"
              :value="elem.key"
              v-model="onetimePaymentMethodId"
              @change="onPaymentMethodChange"
            />
            <label :for="`option${elem.key}`" class="ml5 payment-method-label">
              <span class="payment-method-text">{{elem.val}}</span>
              <img
                :src="onetimePaymentMethodImgUrl(elem.key)"
                class="payment-method-img"
                :class="`payment-method-img-${elem.key}`"
                :alt="elem.val"
              />
            </label>
          </div>
          <div v-if="elem.key === lovKeyOnetimePaymentMethodPaid" class="payment-method-paid-additional-area-container">
            <template v-if="paidState.canCreateAccount">
              <div class="button-row">
                <button
                  class="create-paid-account-button"
                  @click="createPaidAccount"
                >
                  後払いPaidのアカウントを新規作成
                </button>
              </div>
              <div class="description-row">
                <p>※オーサムウォッシュに登録いただいている情報をもとにPaidのアカウントを作成します</p>
              </div>
            </template>
            <template v-else-if="paidAccountCreateSucceeded">
              <div class="account-create-succeeded">
                <p>Paidアカウントを作成しました。</p>
                <p>確認画面へとお進みください。</p>
              </div>
            </template>
            <template v-else-if="paidState.accountStatusErrors.length > 0">
              <div>
                <p v-for="errorObj in paidState.accountStatusErrors" class="error-row">
                  ※{{errorObj.error_message}}
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { defineComponent, getCurrentInstance, onMounted, ref, computed } from 'vue';
import { getValidOneTimePaymentMethodKeys } from '@/utils/userUtils'
import { provideMaster } from '@/composables/useMaster'
import { usePaidPaymentMethod } from '@/composables/usePaidPaymentMethod'
import { lovKeyOnetimePaymentMethodPaid } from '@/consts/lov'

export default defineComponent({
  setup() {
    const vue = getCurrentInstance().proxy

    const masterState = provideMaster() // 親がまだcomposition apiを使ってないため、仕方なくここでprovide
    const onetimePaymentMethodId = ref('1')
    const userIsCompany = ref(false)
    const isPaymentMethodPending = ref(false)

    const paidAccountCreateSucceeded = ref(false)
    // 単発依頼は今のところせいぜい5000円程度なので、dummy金額もそれにならう.
    const {
      state: paidState,
      isAccountReady: isPaidAccountReady,
      loadAccountStatus: loadPaidAccountStatus,
      createAccount: _createPaidAccount,
    } = usePaidPaymentMethod({ dummyPriceForAccountStatusCheck: 5000 })

    const isPaidPaymentMethodId = computed(() => {
      return onetimePaymentMethodId.value === '3'
    })

    const paymentMethodStateAdditionalInfo = computed(() => {
      return isPaidPaymentMethodId.value
        ? { frdKey: paidState.frdKey }
        : {}
    })

    const validOnetimePaymentMethodKeys = computed(() => {
      return getValidOneTimePaymentMethodKeys(userIsCompany.value)
    })

    const onetimePaymentMethods = computed(() => {
      if (!masterState.lovs || !masterState.lovs.onetime_payment_method) {
        return []
      }
      return masterState.lovs.onetime_payment_method.filter(pm => {
        return validOnetimePaymentMethodKeys.value.includes(pm['key'])
      })
    })

    const onetimePaymentMethodImgUrl = (key) => {
      switch(key) {
        case '1':
          return '/static/images/payment-method-creditcard.png'
        case '2':
          return '/static/images/payment-method-paypay.png'
        case '3':
          return '/static/images/payment-method-paid.png'
        default:
          return ''
      }
    }

    const onPaymentMethodStateChange = () => {
      vue.$emit('payment-method-state-change', {
        paymentMethodId: onetimePaymentMethodId.value,
        isPending: isPaymentMethodPending.value,
        additionalInfo: paymentMethodStateAdditionalInfo.value,
      })
    }

    const onPaymentMethodChange = () => {
      if (!isPaidPaymentMethodId.value) {
        isPaymentMethodPending.value = false
      } else {
        isPaymentMethodPending.value = !isPaidAccountReady.value

        if (!paidState.isAccountStatusLoaded) {
          // Paidアカウント情報をロードする
          loadPaidAccountStatus().then(() => {
            vue.$nextTick(() => {
              // ロード後に改めて確認
              isPaymentMethodPending.value = !isPaidAccountReady.value
              onPaymentMethodStateChange()
            })
          })
        }
      }

      onPaymentMethodStateChange()
    }

    const createPaidAccount = async () => {
      const result = await _createPaidAccount()
      if (result) {
        paidAccountCreateSucceeded.value = true
        // 登録成功したのでpendingを解除
        isPaymentMethodPending.value = false
        onPaymentMethodStateChange()
      }
    }

    onMounted(async () => {
      const me = await vue.$store.dispatch('user/getMe')
      userIsCompany.value = me.is_company
    })

    return {
      paidState,
      onetimePaymentMethodId,
      onetimePaymentMethods,
      paidAccountCreateSucceeded,
      onetimePaymentMethodImgUrl,
      onPaymentMethodChange,
      createPaidAccount,

      lovKeyOnetimePaymentMethodPaid,
    }
  },
})
</script>

<style lang="scss" scoped>
.payment-method-row {
  display: flex;
  align-items: center;
  height: 36px;
  overflow: hidden;

  .payment-method-label {
    display: flex;
    align-items: center;
  }
  .payment-method-text {
    width: 172px;
  }
  .payment-method-img {
    // Credit card
    &.payment-method-img-1 {
      height: 30px;
      margin-left: -5px;
    }
    // Paypay
    &.payment-method-img-2 {
      height: 40px;
      margin-left: -1px;
    }
    // Paid
    &.payment-method-img-3 {
      height: 20px;
    }
  }
}
.payment-method-paid-additional-area-container {
  margin: 8px 32px 0;

  .button-row {
    .create-paid-account-button {
      color: white;
      background-color: rgb(108, 171, 69);
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .description-row {
    margin: 4px 0 0;
    color: dimgray;
    font-size: 14px;
    max-width: 350px;
  }
  .account-create-succeeded {
    color: #ff7b45;
    font-size: 14px;
  }
  .error-row {
    color: red;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .payment-method-paid-additional-area-container {
    margin: 8px 24px 0;

    .button-row {
      display: flex;
      justify-content: center;
    }

    .description-row {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
