<template>
<div class="contents_855">
  <header-bar active=""></header-bar>

  <div>
    <h1 class="title2 bg_orange">退会申込み完了</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg40 mg_bt40 font_orange">
        ご利用いただき、ありがとうございました。<br>
        退会の申込みを承りました。
      </p>
      <p class="mypage_notes mg40">
        ご登録のメールアドレスに確認のメールを送信しましたので、ご確認ください。
      </p>
      <p class="mypage_notes mg40 font_orange">
        マイページは継続してお使いいただけます。<br>
        再度お申込みいただく場合は、マイページからお申込みください。
      </p>
      <p class="mypage_notes mg40 font_orange">
        今後もどうぞよろしくお願いいたします。
      </p>
    </div>
    <p class="submit_bx1">
      <a href="https://awesome-wash.com" class="submit1 bg_green"
          style="width:300px;">
        オーサムウォッシュ ホームへ
      </a>
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: 'withdrawal-complete',
}
</script>

<style lang="scss" scoped>
.submit_bx1{
  width: 300px;
}
.survey_btn{
  width: 100%;
  padding: 16px 0px 16px 0px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius:4px;
  background-color:#daa520;
  color: #ffffff;
  text-align:center;
  line-height:30px;
  text-decoration:none;
  font-size: 18px;
}
a.survey_btn:link {
  display:block;
}
</style>
