<template>
  <div class="contents_box">
    <table class="form_tab">
      <tr>
        <th><p class="item2">お名前</p></th>
        <td><p>
          <span>{{userState.name1}}</span>
          <span>{{userState.name2}}</span>
        </p></td>
      </tr>
      <tr>
        <th><p class="item2">お名前（カナ）</p></th>
        <td><p>
          <span>{{userState.kana1}}</span>
          <span>{{userState.kana2}}</span>
        </p></td>
      </tr>
      <tr>
        <th><p class="item2">集配先住所</p></th>
        <td>
          <p>〒{{zipcodeDisp(userState.send_addr_zipcode)}}</p>
          <p>{{userState.send_addr_detail1}}{{userState.send_addr_detail2}}</p>
          <p>{{userState.send_addr_detail3}}</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">電話番号</p></th>
        <td><p>{{userState.phone_number}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">メールアドレス</p></th>
        <td><p>{{userState.email}}</p></td>
      </tr>
      <tr v-if="showPaymentMethod">
        <th><p class="item2">お支払い方法</p></th>
        <td><p>{{paymentMethodDisp}}払い</p></td>
      </tr>
      <tr>
        <th class="form_line_l"><p class="item2">備考</p></th>
        <td class="form_line_r"><p v-html="lineBreakToBR(userState.bikou1)"></p></td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue, { computed, defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useMaster } from '@/composables/useMaster'

export default defineComponent({
  props: {
    showPaymentMethod: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const master = useMaster()
    const { userState } = useUserState()

    const paymentMethodDisp = computed(() => {
      const obj = (master.lovsMap.payment_method || {})[userState.payment_method_id]
      if (!obj) { return 0 }
      return obj.val
    })

    const zipcodeDisp = (zc) => {
      if (!zc || zc.length !== 7) { return zc }
      return zc.slice(0, 3) + '-' + zc.slice(3, 7)
    }

    return {
      userState,
      // computed
      paymentMethodDisp,
      // methods
      zipcodeDisp,
      lineBreakToBR: Vue.filter('lineBreakToBR'),
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
