<template>
  <tr>
    <th><p class="item3">{{itemLabel}}（確認）</p></th>
    <td>
      <p>
        <input
          type="text"
          class="form_basic form_70p"
          v-model="userState[`${itemName}_confirm`]"
          :class="{error_outline: userErrorState.errors[`${itemName}_confirm`]}"
        >
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${itemName}_confirm_required`]">
          ▲ 必須項目です
        </span>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${itemName}_confirm_unmatch`]">
          ▲ 確認のため、{{itemLabel}}と同じ内容を入力してください
        </span>
      </p>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'

export default defineComponent({
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    itemName: {
      type: String,
      required: true,
      validator(value) {
        return [
          'email',
          'email2',
        ].includes(value)
      },
    },
  },
  setup() {
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    return {
      userState,
      userErrorState,
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
