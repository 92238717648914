import { inject, provide, reactive } from 'vue'
import { dateOptions, hourOptions, minuteOptions, monthOptions } from '@/consts/selectOptions'

const injectionKey = 'Register.useCommonState'

function getInitialState() {
  return {
    currentStep: 1,

    lovs: {},
    plans: [],
    planMap: {},
    lovsMap: {},
    sizeDisplay: {},
    months: monthOptions,
    dates: dateOptions,
    hours: hourOptions,
    minutes: minuteOptions,
    counts: [
      1,2,3,4,5
    ],
    other: {},

    planPart1: '',
    planPart2: '',
    bagSize: '',
    planId: '',
    showBagSizeSelect: true,
    isOnetime: false,
    showPdSameTime: false,
    pdSameTime: '',
    showSameTimeDeliveryNoteModal: false,
    count: 1,
    price: '',
    unitPrice: '',
    planParts1: [],
    agreeToTerms: false,

    areaInfos: [],

    errors: {},
  }
}

export const provideCommonState = () => {
  const state = reactive(getInitialState())
  provide(injectionKey, state)
  return state
}

export const useCommonState = () => {
  const state = inject(injectionKey)
  if (!state) {
    throw new Error('useCommonState() is called without provider.')
  }
  return state
}
