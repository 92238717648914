<template>
  <modal
    v-if="true"
    :showTopDismissButton="true"
    :showFooter="false"
    @dismiss="dismiss"
  >
    <div slot="body">
      <div class="modal-content pt10 pb10">
        <p class="font_blue">・集配別</p>
        <p>集荷日時と配達日時を別で選択できます。</p>
        <p class="font_blue"><br>・集配同時</p>
        <p>集荷日時だけ選択いただき、集荷時に前回分を配達します。<br>集荷と配達を同時に行なうため料金が安くなります。</p>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    dismiss() {
      this.$emit('dismiss')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  font-size: 1.0rem;
}
</style>
