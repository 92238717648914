import { inject, provide, reactive } from 'vue'

const injectionKey = 'useMaster'

function getInitialState() {

  return {
    _masterPromise: window.master.$promise,
    lovs: {},
    plans: [],
    planMap: {},
    lovsMap: {},
    other: {},
  }
}

export const provideMaster = () => {
  const state = reactive(getInitialState())
  provide(injectionKey, state)

  state._masterPromise.then(masterData => {
    state.lovs = masterData.lovs
    state.plans = masterData.plans
    state.planMap = masterData.planMap
    state.lovsMap = masterData.lovsMap
    state.other = masterData.other
  })

  return state
}

export const useMaster = () => {
  const state = inject(injectionKey)
  if (!state) {
    throw new Error('useMaster() is called without provider.')
  }
  return state
}
