export function validateUserPaymentMethod(userState, planId, bagSize) {
  const errors = {}

  const planInfos = planId.split('_')
  const planPart1 = planInfos[0]
  const isOnetimePlan = planPart1 === 'tx'

  if (!userState.is_company && userState.payment_method_id === '2') {
    errors['payment_method_unselectable'] = true
  }

  //以下、 会社(店舗)は請求書を選択しがちだが、運営上はクレカにしてくれたほうが助かるのでなるべくクレカに寄せたい.

  // 単発プランでは請求書を選択させない
  if (isOnetimePlan  && userState.payment_method_id === '2') {
    errors['payment_method_onetime'] = true
  }

  // バッグSサイズの場合で月2,4回プランの場合は(金額が小さいので)請求書を選択させない
  if (bagSize === '1' && (planPart1 === 't2' || planPart1 === 't4') && userState.payment_method_id === '2') {
    errors['payment_method_plans'] = true
  }

  // バッグMサイズの場合で月2回プランの場合は(金額が小さいので)請求書を選択させない
  if (bagSize === '2' && planPart1 === 't2' && userState.payment_method_id === '2') {
    errors['payment_method_plans'] = true
  }

  const isOk = Object.keys(errors).length === 0
  return [isOk, errors]
}
