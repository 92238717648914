<template>
<div class="contents_855">
  <div v-show="isStep1">
    <h1 class="title2">プラン変更</h1>
    <div class="contents_box mg20">
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item2">プラン<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <select v-model="planPart1" class="w200" @change="onPlanChange()"
                  :class="{error_outline: this.errors.planPart1}">
                <option value="">選択してください</option>
                <option v-for="elem in planParts1" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </p>
            <p>
              <span class="iferror" v-show="errors.planPart1_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.notarea">
                ▲ 提供エリア外のためご利用いただけません
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">集配方法<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <select v-model="planPart2" class="w250" @change="onPlanChange()"
                  :class="{error_outline: this.errors.planPart2}">
                <option value="">選択してください</option>
                <option v-for="elem in lovs.pd_type" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </p>
            <p>
              <span class="iferror" v-show="errors.planPart2_required">
                ▲ 必須項目です
              </span>
            </p>
            <p v-show="showPdSameTimeMsg">
              <input type="checkbox" id="sameTime" v-model="pdSameTime" @change="onPlanChange()">
              <label for="sameTime" class="font_orange">配達と次回の集荷を同時に行なう（１個あたり-400円）</label>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">バッグサイズ<span class="font_orange">*</span></p></th>
          <td>
            <p v-if="showBagSizeSlct">
              <select v-model="bagSize" class="w200" @change="onPlanChange()"
                  :class="{error_outline: this.errors.bagSize}">
                <option value="">選択してください</option>
                <option v-for="elem in lovs.size" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </p>
            <p v-else>
              {{SizeForHanding}}
            </p>
            <p>
              <span class="iferror" v-show="errors.bagSize_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr v-show="!isOnetime">
          <th><p class="item2">オプション</p></th>
          <td>
            <p>
              <select v-model="option" @change="onPlanChange()" style="width:240px;">
                <option value="0">なし</option>
                <option v-for="elem in lovs.user_plan_option" :value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">料金</p></th>
          <td>
            <p v-if="isOnetime">日時に応じて都度決済されます</p>
            <p v-else>{{price | currency('',0)}}円({{unitPrice | currency('',0)}}円/個)</p>
          </td>
        </tr>
        <tr>
          <th class="form_line_l"></th>
            <td class="form_line_r">
              <edit-plan-notice-messages></edit-plan-notice-messages>
            </td>
        </tr>
      </table>

      <div class="err-msg-area" v-show="hasError">
        <span class="font_orange">
          入力エラーがあります
        </span>
      </div>

      <p class="submit_bx_l">
        <button class="submit1 bg_gray"
          @click="backToWreqList()">
          戻る
        </button>
      </p>
      <p class="submit_bx_r">
        <button @click="nextStep()" class="submit1 bg_green">
          確認画面
        </button>
      </p>
    </div>
  </div>

  <div v-show="isStep2">
    <h1 class="title2">プラン変更</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        下記の情報でよろしければ送信してください。
      </p>
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item2">プラン<span class="font_orange">*</span></p></th>
          <td><p>{{planPart1Disp}}</p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">集配方法<span class="font_orange">*</span></p></th>
          <td><p>{{planPart2Disp}}</p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">料金</p></th>
          <td>
            <p v-if="isOnetime">日時に応じて都度決済されます</p>
            <p v-else>{{price | currency('',0)}}円({{unitPrice | currency('',0)}}円/個)</p>
          </td>
        </tr>
      </table>

      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
      </div>

      <p class="submit_bx_l">
        <button @click="prevStep()" class="submit1 bg_gray">
          戻る
        </button>
      </p>
      <p class="submit_bx_r">
        <button @click="updatePlan()" class="submit1 bg_green">
          OK
        </button>
      </p>

      <form id="payment-form" method="post"
          :action="paymentInfo.url" accept-charset="Shift_JIS">
        <input type="hidden" v-for="elem in paymentInfo.formParams"
          :name="elem.name" :value="elem.value">
        <input type="hidden" name="RETURL" :value="paymentInfo.retUrl">
      </form>
    </div>
  </div>

</div>
</template>

<script>
import masterApi from '@/api/master'
import adminUser from '@/api/adminUser'
import adminAreaInfoApi from '@/api/adminAreaInfo'
import adminUserMixin from '@/mixin/adminUserMixin'

export default {
  name: 'admin-plan-edit',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
    searchPrm: {
      type: Object,
      'default': () => ({
        registerDtFrom: '',
        registerDtTo: '',
        userId: '',
        name: '',
        kana: '',
        billAddrZipcode: '',
        phoneNumber: '',
        email: '',
        status: '',
      })
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      lovs: {},
      planMap: {},
      lovsMap: {},
      optionMap: {},
      optionMagMap: {},

      planPart1: '',
      planPart2: '',
      bagSize: '',
      showBagSizeSlct: true,
      showPdSameTimeMsg: false,
      pdSameTime: false,
      option: 0,
      planId: '',
      count: 1,
      price: '',
      unitPrice: '',
      planParts1: [],
      isOnetime: false,

      areaInfos: [],
      zipcode: '',

      paymentInfo: {
        url: '',
        formParams: [],
        retUrl: '',
      },

      SizeForHanding: '',

      roleType: 0,
      errors: {},
      serverErrors: [],
    }
  },
  watch: {
    bagSize: function(newBagSize, oldBagSize) {
        const planParts1 = this.lovs.plan_part.filter(p => {
          // お試しプランは選択不可
          if (p['key'] === 't0') {
            return false
          // bagSizeがS,Mの場合は40、48、56個を選択できないようにする
          } else if ((newBagSize == 1 || newBagSize == 2) && (p['key'] === 't40' || p['key'] === 't48' || p['key'] === 't56')) {
            return false
          }
          return true
        })
        this.planParts1 =  planParts1

        // 40、48、56個を選択した状態で、bagSizeをS,Mに変更した場合は個数をリセットする
        if ((newBagSize == 1 || newBagSize == 2) && (this.planPart1 === 't40' || this.planPart1 === 't48' || this.planPart1 === 't56')) {
          this.planPart1 = ''
        }
    }
  },
  computed: {
    planPart1Disp() {
      const plan = this.planMap[this.planId]
      if (!plan) { return '' }
      return plan.name + this.optionDisp()
    },
    planPart2Disp() {
      const pdType = this.lovs.pd_type
      if (!pdType) { return '' }
      let ret = ''
      pdType.forEach(e => {
        if (e.key === this.planPart2) {
          ret = e.val
        }
      })
      return ret
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  beforeRouteLeave (to, from, next) {
    // ブラウザバック時にパラメータがないため追加
    to.params.searchPrm = this.searchPrm
    next()
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 種別なしのスタッフはアクセス不可
    if (this.adminType === 'staff' && this.roleType === 0) {
      this.$router.push({ name: 'staff-top' })
    }
    window.master.$promise.then(mst => {
      this.lovs = mst.lovs
      this.planMap = mst.planMap
      this.lovsMap = mst.lovsMap
      this.optionMap = mst.lovsMap.user_plan_option
      this.optionMagMap = mst.lovsMap.user_plan_option_mag

      adminUser.getUserCurrentPlan(this.id).then(({ data }) => {
        //plan_idを_で区切って３つ目の値が1なら集配同時
        //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
        const planInfos = data.plan_id.split('_')
        if (planInfos.length > 2 && planInfos[2] == 1) {
          this.pdSameTime = true
        } else {
          this.pdSameTime = false
        }
        this.planPart1 = data.plan_id.split('_')[0]
        this.planPart2 = data.pickup_delivery_type
        this.bagSize = this.planMap[data.plan_id].size
        this.option = data.user_plan_option
        this.zipcode = data.user.send_addr_zipcode

        this.onPlanChange()
      })
      if (this.lovs.plan_part) {
        const planParts1 = this.lovs.plan_part.filter(p => {
          // お試しプランは選択不可
          return p['key'] !== 't0'
        })
        this.planParts1 =  planParts1
      }
    })
    adminAreaInfoApi.index().then(({ data }) => {
      this.areaInfos = data
    })
  },
  mixins: [adminUserMixin],
  methods: {
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    checkStep1() {
      this.errors = {}
      let result = true

      if (!this.planPart1) {
        this.errors['planPart1'] = true
        this.errors['planPart1_required'] = true
        result = false
      }
      if (!this.planPart2) {
        this.errors['planPart2'] = true
        this.errors['planPart2_required'] = true
        result = false
      }
      if (!this.bagSize) {
        this.errors['bagSize'] = true
        this.errors['bagSize_required'] = true
        result = false
      }
      if (!this.checkArea({plan_id: this.planId, zipcode: this.zipcode}, this.areaInfos)) {
        this.errors['notarea'] = true
        result = false
      }

      return result
    },
    checkStep2() {
      return true
    },
    onPlanChange() {
      if (this.planPart1 == 'tx') {
        this.bagSize = this.lovsMap.size[1].key
        this.SizeForHanding = '単発（45L）';
        this.option = 0
        this.showBagSizeSlct = false
        this.isOnetime = true
      } else {
        this.isOnetime = false
        this.showBagSizeSlct = true
      }
      if (!this.planPart1 || !this.planPart2 || !this.bagSize || !this.count) {
        if (this.planPart1 && this.planPart2) {
          this.showPdSameTimeMsg = true
        }
        this.price = ''
        this.unitPrice = ''
        return
      }
      // 直接受け渡す以外は不在受け渡しとする
      const planIdSuffix = this.planPart2 === '1' ? '1' : '2'

      //Sサイズ以外はplanIdにサイズを追記する
      let bagSizeSuffix = ''
      const sizeMap = this.lovsMap.size
      if (this.bagSize != 1) {
        bagSizeSuffix = '_' + sizeMap[this.bagSize].val
      }

      // 単発プランの場合、初回費用固定
      if (this.planPart1 === 'tx') {
        this.planId = this.planPart1 + '_' + planIdSuffix
        this.price = ''
        this.unitPrice = ''
        this.showPdSameTimeMsg = false
        return
      }
      // お試しプランは選択不可のため、お試しプラン契約の場合は初期値を選択
      if (this.planPart1 === 't0') {
        this.planPart1 = ''
        this.price = ''
        this.unitPrice = ''
        this.showPdSameTimeMsg = false
        return
      }
      // 月２個プランは、集配同時プランはない
      if (this.planPart1 === 't2') {
        this.planId = this.planPart1 + '_' + planIdSuffix + bagSizeSuffix
        this.price = this.planMap[this.planId].price * this.count
        this.setOptionPrice()
        this.unitPrice = this.price / this.planMap[this.planId].wash_count
        this.showPdSameTimeMsg = false
        return
      }
      // 配達と次回の集配の集配を同時にする場合、末尾に付与
      this.showPdSameTimeMsg = true
      const planIdSuffix2 = this.pdSameTime ? '_1' : ''
      this.planId = this.planPart1 + '_' + planIdSuffix + planIdSuffix2 + bagSizeSuffix
      if (!this.planMap[this.planId]) {
        this.price = ''
        this.unitPrice = ''
        return
      }
      this.price = this.planMap[this.planId].price * this.count
      this.setOptionPrice()
      this.unitPrice = this.price / this.planMap[this.planId].wash_count
    },
    setOptionPrice(){
      const obj = this.optionMagMap[this.option]
      if (obj) {
        this.price = this.price * obj.val
      }
    },
    optionDisp() {
      const obj = this.optionMap[this.option]
      return obj ? '/' + obj.val : ''
    },
    updatePlan() {
      this.serverErrors = []
      const data = {
        plan_id: this.planId,
        pickup_delivery_type: this.planPart2,
        user_plan_option: this.option,
      }
      adminUser.updateUserPlan(this.id, data)
        .then(() => {
          let name = 'admin-plan-edit-complete'
          if (this.adminType === 'staff') {
            name = 'staff-plan-edit-complete'
          }
          this.$router.push({ name: name })
        })
        .catch(e => {
          const errRes = e.response.data.errors || [];
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
    backToWreqList() {
      let name = 'admin-users'
      if (this.adminType === 'staff') {
        name = 'staff-users'
      }
      this.$router.push({
        name: name,
        params: {
          id: this.id,
          searchPrm: this.searchPrm,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>
