<template>
  <div class="contents_box">
    <table class="form_tab">
      <tr v-if="showContractType">
        <th><p class="item3">ご契約者<span class="font_orange">*</span></p></th>
        <td>
          <div class="wrap_input">
            <div class="slc">
              <input type="radio" id="is-company-option1" :value="1" checked />
              <label for="is-company-option1" class="ml5">個人</label>
            </div>
            <div class="slc">
              <input type="radio" id="is-company-option2" :value="2" @change="onContractModeChange"/>
              <label for="is-company-option2" class="ml5">法人・個人事業主</label>
            </div>
          </div>
        </td>
      </tr>
      <NameInput
        item-label="お名前"
        item-name-prefix="name"
      />
      <NameInput
        item-label="お名前（カナ）"
        item-name-prefix="kana"
      />
      <AddressInput
        item-label="集配先住所"
        item-name-prefix="send"
      />
      <PhoneNumberInput
        item-label="電話番号"
        item-name="phone_number"
      />
      <EmailInput
        item-label="メールアドレス"
        item-name="email"
      />
      <EmailConfirmInput
        item-label="メールアドレス"
        item-name="email"
      />
      <PasswordInput v-if="showPassword" />
      <PasswordConfirmInput v-if="showPassword" />
      <PaymentMethodInput
        v-if="showPaymentMethod"
        :is-company="false"
      />
      <BikouInput />
    </table>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import NameInput from '@/components/lib/user/components/inputFormComponents/NameInput.vue'
import AddressInput from '@/components/lib/user/components/inputFormComponents/AddressInput.vue'
import PasswordInput from '@/components/lib/user/components/inputFormComponents/PasswordInput.vue'
import PasswordConfirmInput from '@/components/lib/user/components/inputFormComponents/PasswordConfirmInput.vue'
import PaymentMethodInput from '@/components/lib/user/components/inputFormComponents/PaymentMethodInput.vue'
import BikouInput from '@/components/lib/user/components/inputFormComponents/BikouInput.vue'
import EmailInput from '@/components/lib/user/components/inputFormComponents/EmailInput.vue'
import EmailConfirmInput from '@/components/lib/user/components/inputFormComponents/EmailConfirmInput.vue'
import PhoneNumberInput from '@/components/lib/user/components/inputFormComponents/PhoneNumberInput.vue'

export default defineComponent({
  name: 'InputFormIndividual',
  components: {
    PhoneNumberInput,
    EmailConfirmInput,
    EmailInput,
    BikouInput,
    PaymentMethodInput,
    PasswordConfirmInput,
    PasswordInput,
    NameInput,
    AddressInput,
  },
  props: {
    showContractType: {
      type: Boolean,
      required: true,
    },
    showPassword: {
      type: Boolean,
      required: true,
    },
    showPaymentMethod: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const vue = getCurrentInstance().proxy

    function onContractModeChange() {
      vue.$emit('on-contract-mode-change', { isCompany: true })
    }

    return {
      // methods
      onContractModeChange,
    }
  }
})
</script>

<style lang="scss" scoped>
.wrap_input {
  width: 100%;
  margin: 0 auto 0 auto;
  text-align: left;
  overflow: hidden;
}
.wrap_input .slc {
  float: left;
  margin: 10px 15px 0 0;
}

@media screen and (max-width: 640px) {
  .wrap_input .slc {
    float: none;
  }
}
</style>
