<template>
  <div class="contents_855">
    <div class="contents_box">
      <p class="mg_bt30 font_orange">下記の内容でよろしければ送信してください。</p>
    </div>
    <h2 class="title">お申込み内容</h2>
    <ReadonlyViewPlanParts />

    <h2 class="title mg40">お客様情報</h2>
    <ReadonlyViewIndividual
      v-if="!isCompanyMode"
      :show-payment-method="!commonState.isOnetime"
    />
    <ReadonlyViewCompany
      v-else
      :show-payment-method="!commonState.isOnetime"
    />

    <div class="err-msg-area">
      <p><span class="font_orange" v-for="e in serverErrors">
        {{e}}
      </span></p>
    </div>

    <p class="submit_bx_l">
      <button @click="backToInputStep" class="submit1 bg_gray">
        戻る
      </button>
    </p>
    <p v-if="userState.payment_method_id === '1' && !commonState.isOnetime" class="submit_bx_r">
      <button @click="doRegister" class="submit1 bg_green">
        カード情報入力へ
      </button>
    </p>
    <p v-else class="submit_bx_r">
      <button @click="doRegister" class="submit1 submit2 bg_green">
        OK
      </button>
    </p>

    <form id="payment-form" method="post" :action="paymentInfoState.url" accept-charset="Shift_JIS">
      <input type="hidden" v-for="elem in paymentInfoState.formParams" :name="elem.name" :value="elem.value">
      <input type="hidden" name="RETURL" :value="paymentInfoState.retUrl">
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, reactive, ref } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useCommonState } from '@/components/Register/composables/useCommonState'
import userApi from '@/api/user'
import ReadonlyViewPlanParts from '@/components/Register/components/ReadonlyViewPlanParts.vue'
import ReadonlyViewIndividual from '@/components/lib/user/components/ReadonlyViewIndividual.vue'
import ReadonlyViewCompany from '@/components/lib/user/components/ReadonlyViewCompany.vue'

export default defineComponent({
  components: { ReadonlyViewCompany, ReadonlyViewIndividual, ReadonlyViewPlanParts },
  setup() {
    const vue = getCurrentInstance().proxy
    const commonState = useCommonState()
    const { userState } = useUserState()
    const paymentInfoState = reactive({
      url: '',
      formParams: [],
      retUrl: '',
    })
    const serverErrors = ref([])

    const isCompanyMode = computed(() => {
      return userState.is_company
    })

    function backToInputStep() {
      vue.$emit('back-to-input-step')
    }

    async function doRegister() {
      serverErrors.value = [];
      const payment_method_id = userState.payment_method_id

      const reqData = { ...userState }
      reqData.plan_id = commonState.planId
      reqData.pickup_delivery_type = commonState.planPart2

      let responseData = {}
      try {
        const response = await userApi.register(reqData)
        responseData = response.data
      } catch (e) {
        const errRes = e.response.data.errors
        let errors = []
        Object.keys(errRes).forEach(k => {
          errors = errors.concat(errRes[k])
        })
        serverErrors.value = errors
        return
      }

      if (payment_method_id === '1' && !commonState.isOnetime) {
        // クレジット決済(単発以外)
        doA8sales(responseData)
        updatePaymentFormValues(responseData.payment)

        setTimeout(() => {
          const form = document.querySelector('#payment-form')
          form.submit()
        }, 0)
      } else {
        // 請求書、単発
        await vue.$store.dispatch('localStorage/set', {
          key: 'serverToken',
          val: responseData.token.access_token,
        })

        const nextRouteName = commonState.isOnetime ? 'onetime-register-complete' : 'register-complete'
        await vue.$router.push({
          name: nextRouteName,
          params: {
            id: responseData.user.id,
            payment_method_id: responseData.user.payment_method_id,
          }
        })
      }
    }
    function updatePaymentFormValues(obj) {
      paymentInfoState.url = obj.url
      paymentInfoState.formParams = obj.form_params
      paymentInfoState.retUrl = `${location.protocol}//${location.host}/${obj.ret_url_path}`
    }

    function doA8sales(obj) {
      const t_no = obj.payment.form_params.find(item => item.name === 'S_TORIHIKI_NO')
      // const user_id = obj.user.id
      const order_number = t_no.value //+ '_' + user_id

      window.a8sales({
        "pid": "s00000020811001",
        "order_number": order_number,
        "currency": "JPY",
        "items": [
          {
            "code": "a8",
            "price": 1000,
            "quantity": 1
          },
        ],
        "total_price": 1000
      })
    }

    return {
      commonState,
      userState,
      paymentInfoState,
      serverErrors,
      // computed
      isCompanyMode,
      // methods
      backToInputStep,
      doRegister,
    }
  }
})
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>
