<template>
  <tr>
    <th><p class="item3">{{itemLabel}}<span class="font_orange">*</span></p></th>
    <td>
      <p class="pc_br_none tab_br_none sp_br_yes">姓
        <input type="text" size="15" class="form_basic" v-model="userState[`${itemNamePrefix}1`]"
               :class="{error_outline: userErrorState.errors[itemNamePrefix]}">　<br>名
        <input type="text" size="15" class="form_basic" v-model="userState[`${itemNamePrefix}2`]"
               :class="{error_outline: userErrorState.errors[itemNamePrefix]}">
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${itemNamePrefix}_required`]">
          ▲ 必須項目です
        </span>
      </p>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'

export default defineComponent({
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    itemNamePrefix: {
      type: String,
      required: true,
      validator(value) {
        return [
          'name',
          'kana',
          'company_ceo_name',
          'company_ceo_name_kana',
        ].includes(value)
      },
    },
  },
  setup() {
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    return {
      userState,
      userErrorState,
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
