import { inject, provide, reactive } from 'vue'
import { dateOptions, hourOptions, minuteOptions, monthOptions } from '@/consts/selectOptions'

const injectionKey = 'ProfileEdit.useCommonState'

function getInitialState() {
  return {
    areaInfos: [],
  }
}

export const provideCommonState = () => {
  const state = reactive(getInitialState())
  provide(injectionKey, state)
  return state
}

export const useCommonState = () => {
  const state = inject(injectionKey)
  if (!state) {
    throw new Error('useCommonState() is called without provider.')
  }
  return state
}
