var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contents_box"},[_c('table',{staticClass:"form_tab"},[_c('tr',[_vm._m(0),_c('td',[_c('p',[_vm._v(_vm._s(_vm.planPart1Disp))])])]),_c('tr',[_vm._m(1),_c('td',[_c('p',[_vm._v(_vm._s(_vm.planPart2Disp))])])]),(!_vm.commonState.isOnetime)?_c('tr',[_vm._m(2),_c('td',[_c('p',[_vm._v(_vm._s(_vm._f("currency")(_vm.commonState.price,'',0))+"円（"+_vm._s(_vm._f("currency")(_vm.commonState.unitPrice,'',0))+"円/個）")])])]):_vm._e(),(!_vm.commonState.isOnetime && _vm.commonState.bagSize)?[(_vm.bagSizeAlphabetValue === 'S')?_c('tr',[_vm._m(3),_c('td',[_vm._m(4),_c('p',[_vm._v("※追加購入の場合は1個"+_vm._s(_vm._f("currency")(_vm.bagPrice,'',0))+"円")])])]):_c('tr',[_vm._m(5),_c('td',[_c('p',[_vm._v(_vm._s(_vm._f("currency")((_vm.bagPrice * _vm.numBags),'',0))+"円（"+_vm._s(_vm._f("currency")(_vm.bagPrice,'',0))+"円×"+_vm._s(_vm.numBags)+"個）")]),(_vm.numBags > 1)?_c('p',[_vm._v("※「集配同時」はバッグが2個必要です")]):_vm._e()])])]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("プラン"),_c('span',{staticClass:"font_orange"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("集配方法"),_c('span',{staticClass:"font_orange"},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("プラン料金")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("バッグ代金")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('span',[_vm._v("0円")]),_c('span',{staticClass:"diblk ml12"},[_vm._v("(初回無料)")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("バッグ代金")])])
}]

export { render, staticRenderFns }