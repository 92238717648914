<template>
<div class="contents_855">
  <header-bar active="profile"></header-bar>

  <InputStepView
    v-if="isInputStep"
    @back="backToProfileView"
    @next="toConfirmStep"
  />
  <ConfirmStepView
    v-else-if="isConfirmStep"
    @back="backToInputStep"
    @next="toCompleteStep"
  />
  <div v-else-if="isCompleteStep">
    <h1 class="title2">お客様情報の変更</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        お客様情報を変更しました
      </p>
    </div>
  </div>
</div>
</template>

<script>
import { computed, reactive, defineComponent, getCurrentInstance, onMounted } from 'vue'
import { provideFilledUserState } from '@/components/lib/user/composables/useUserState'
import { provideUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import InputStepView from './components/InputStepView.vue'
import ConfirmStepView from './components/ConfirmStepView.vue'
import areaInfoApi from '@/api/areaInfo'
import { provideCommonState } from '@/components/ProfileEdit/composables/useCommonState'
import { provideMaster } from '@/composables/useMaster'

export default defineComponent({
  components: { InputStepView, ConfirmStepView },
  setup() {
    const vue = getCurrentInstance().proxy
    const state = reactive({
      currentStep: 1,
    })
    const commonState = provideCommonState()
    provideFilledUserState()
    provideUserErrorState()
    provideMaster()

    const isInputStep = computed(() => {
      return state.currentStep === 1
    })
    const isConfirmStep = computed(() => {
      return state.currentStep === 2
    })
    const isCompleteStep = computed(() => {
      return state.currentStep === 3
    })

    function toConfirmStep() {
      state.currentStep = 2
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
    function toCompleteStep() {
      state.currentStep = 3
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
    function backToInputStep() {
      state.currentStep = 1
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
    function backToProfileView() {
      vue.$router.back()
    }

    onMounted(async () => {
      const { data: areaInfos } = await areaInfoApi.index()
      commonState.areaInfos = areaInfos
    })

    return {
      state,
      isInputStep,
      isConfirmStep,
      isCompleteStep,
      toConfirmStep,
      toCompleteStep,
      backToInputStep,
      backToProfileView,
    }
  },
})
</script>

<style lang="scss" scoped>
</style>
