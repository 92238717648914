<template>
<div>
  <no-login-header></no-login-header>

  <h1 class="pagetitle">マイページにログイン</h1>
  <div class="contents_login">
    <form @submit.prevent="submitLogin">
      <p class="item">メールアドレス</p>
      <input type="text" name="id" ref="textInputEmail"
         class="text verifyText form_basic form_100p"
         v-model="loginForm.email"
         required="required">

      <p class="item">パスワード</p>
      <input type="password" name="password" ref="textInputPassword"
         class="text verifyText form_basic form_100p"
         v-model="loginForm.password"
         required="required">

      <p class="submit_bx1">
        <button type="submit"
            class="submit1 bg_blue">
          ログイン
        </button>
      </p>
      <div v-if="showErrorMessage" class="mt5">
        <p class="font_orange">
          ログインIDまたはパスワードが<br>間違っています
        </p>
      </div>
    </form>

    <div class="mg30">
      <router-link :to="{name:'password-forgot'}">
        &gt; パスワードを忘れた場合はこちら
      </router-link>
    </div>
  </div>

  <div class="contents_wrap pc_none">
    <a href="https://awesome-wash.com" target="_blank" class="btn_home link_head radius4">
      <p>AWESOME WASH ホーム</p>
    </a>
  </div>

  <no-login-footer></no-login-footer>
</div>
</template>

<script>
import axios from 'axios'
import masterApi from '@/api/master'

export default {
  name: 'login',
  created() {
    this.$store.dispatch('user/getMe')
      .then((me) => {
        const token = this.$store.getters['localStorage/serverToken']
        if (token) {
          masterApi.maintenanceIndex().then(({ data }) => {
            if (data.maint_flg === 1 && !me.is_admin == 1) {
              this.$router.push({name: 'maintenance'})
            }
          })

          // redirect to mypage default if already logged in
          if (me.is_admin == 1 && me.role == 200) {
          this.$router.replace({name: 'staff-top'})
          } else {
            this.$router.replace({name: 'wash-request'})
          }
        }
      })
      .catch(() => {
        // nothing to do
      })
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      showErrorMessage: false,
    }
  },
  methods: {
    // iOSのchromeだとautofillでchangeが効かないぽいのでがんばる
    ensureLoginFormValuesSynced() {
      const email = this.$refs.textInputEmail.value
      const password = this.$refs.textInputPassword.value
      this.loginForm.email = email
      this.loginForm.password = password
    },
    async submitLogin() {
      this.showErrorMessage = false
      this.ensureLoginFormValuesSynced()

      // loginしようとするときは明示的に消す.
      // expiredなトークンを送り続けるとログインできないので.
      delete axios.defaults.headers['Authorization']

      try {
        const res = await this.$store.dispatch('user/login', this.loginForm)
        await this.$store.dispatch('localStorage/set', {
          key: 'serverToken',
          val: res['access_token'],
        })
        // Business Rule No.11 登録したメアド、PWがあっていればログインできる
        // want to explicitly reload
        let user = res.user
        if (user && user.is_admin == 1 && user.role == 200) {
          this.$router.replace({name: 'staff-top'})
        } else {
          window.location = '/'
        }
      } catch (e) {
        // Business Rule No.12 メアドとPWの組み合わせがが違う場合は、エラーを表示
        this.showErrorMessage = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
