import axios from 'axios'
import * as utils from './api-utils'

export default {
  getAccountOrderableStatus(params) {
    return axios.get(utils.getApiUrl('/paid/account/orderableStatus'), {
      params,
    })
  },
  createAccount(params) {
    return axios.post(utils.getApiUrl(`/paid/account`), params)
  },
}
