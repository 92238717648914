<template>
  <tr>
    <th><p class="item3">パスワード（確認）</p></th>
    <td>
      <p>
        <input type="password" size="20" class="form_basic" v-model="userState.password_confirm" :class="{error_outline: userErrorState.errors.password_confirm}"/>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors.password_confirm_required">
          ▲ 必須項目です
        </span>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors.password_confirm_unmatch">
          ▲ 確認のため、パスワードと同じ内容を入力してください
        </span>
      </p>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'

export default defineComponent({
  setup() {
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    return {
      userState,
      userErrorState,
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
