export function convertUserDataForSave(userState) {
  // 個人ユーザーの場合に、集配先住所名称を自動で付加し、集配先住所を請求先住所にコピーする.
  // 個人/法人フラグをつける前のデータではbill_address系に集配先住所が入っていたが、個人/法人フラグ
  // をつける際に一旦bill_address系をsend_address系にコピーして、以後はsend_address系を集配先住所として取り扱うようにした.
  // しかしコード内で集配先住所としてbill_addressを見ている箇所を先に全部つぶすのが困難と思われたため、
  // とりあえずsend_addressをbill_addressにコピーしておけば困ったことにはならないだろうということでこの処理を入れた.
  if (userState.is_company) { return }

  userState.send_addr_name = '住所1' // 何か入れる
  userState.bill_addr_zipcode = userState.send_addr_zipcode
  userState.bill_addr_detail1 = userState.send_addr_detail1
  userState.bill_addr_detail2 = userState.send_addr_detail2
  userState.bill_addr_detail3 = userState.send_addr_detail3
}

export function getValidPaymentMethodKeys(isCompany) {
  // 法人 => クレカ、請求書
  // 個人 => クレカ
  return isCompany ? ['1', '2'] : ['1']
}

export function getValidOneTimePaymentMethodKeys(isCompany) {
  // 法人 => クレカ、Paypay, 請求書(Paid)
  // 個人 => クレカ, Paypay
  return isCompany ? ['1', '2', '3'] : ['1', '2']
}
