<template>
  <div>
    <h2 class="title">お客様情報の変更</h2>
    <InputFormIndividual
      v-if="!isCompany"
      :show-password="false"
      :show-contract-type="false"
      :show-payment-method="false"
    />
    <InputFormCompany
      v-else
      :show-password="false"
      :show-contract-type="false"
      :show-payment-method="false"
    />

    <div class="err-msg-area" v-show="hasError">
      <span class="font_orange">
        入力エラーがあります
      </span>
    </div>

    <p class="submit_bx_l">
      <button @click="goBack" class="submit1 bg_gray">
        戻る
      </button>
    </p>
    <p class="submit_bx_r">
      <button @click="goNext" class="submit1 bg_green">
        確認画面
      </button>
    </p>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import InputFormCompany from '@/components/lib/user/components/InputFormCompany.vue'
import InputFormIndividual from '@/components/lib/user/components/InputFormIndividual.vue'
import { validateUserParams } from '@/validations/userValidations'
import { convertUserDataForSave } from '@/utils/userUtils'
import { useCommonState } from '@/components/ProfileEdit/composables/useCommonState'

export default defineComponent({
  components: { InputFormIndividual, InputFormCompany },
  setup() {
    const vue = getCurrentInstance().proxy
    const commonState = useCommonState()
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    const hasError = computed(() => {
      return Object.keys(userErrorState.errors).length > 0
    })
    const isCompany = computed(() => {
      return userState.is_company
    })

    function validateInputStep() {
      userErrorState.errors = {}
      let result = true

      const [isUserParamsOk, errors] = validateUserParams(
        userState,
        userState.user_plan.plan_id,
        commonState.areaInfos,
        { noPasswordCheck: true }
      )
      if (!isUserParamsOk) {
        userErrorState.errors = errors
        result = false
      }

      return result
    }

    function goBack() {
      vue.$emit('back')
    }

    function goNext() {
      convertUserDataForSave(userState)

      if (!validateInputStep()) { return }
      vue.$emit('next')
    }

    return {
      userState,
      // computed
      hasError,
      isCompany,
      // methods
      goBack,
      goNext,
    }
  }
})
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
</style>
