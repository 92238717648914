<template>
  <div class="contents_855">
    <div class="contents_box">
      <p class="mg_bt30 font_orange">*は必須項目です</p>
    </div>

    <h2 class="title">お申込み内容</h2>
    <InputFormPlanParts />

    <h2 class="title mg40">お客様情報</h2>
    <InputFormIndividual
      v-if="!isCompanyMode"
      :show-contract-type="true"
      :show-password="true"
      :show-payment-method="!commonState.isOnetime"
      @on-contract-mode-change="onContractModeChange"
    />
    <InputFormCompany
      v-else
      :show-contract-type="true"
      :show-password="true"
      :show-payment-method="!commonState.isOnetime"
      @on-contract-mode-change="onContractModeChange"
    />

    <div class="form_notes">
      <p class="font_big font_orange">お申込み前に<a href="https://awesome-wash.com/agreement/" target="_blank" class="link_ornge">「利用規約」</a>をご確認ください</p><br>
      <p class="center"><input type="checkbox" id="agreement" v-model="commonState.agreeToTerms"><label for="agreement" class="agreement-label">利用規約に同意する</label></p><br>
      <p class="tab_br_none sp_br_none mg_bt30">
        メールの受信拒否設定をされている場合は、<br>
        contact@awesome-wash.comからのメールを受信できるよう受信拒否解除の設定をお願いいたします。
      </p>
    </div>

    <hr class="hr_dot_gray">
    <div class="err-msg-area" v-show="hasError">
      <span class="font_orange">
        入力エラーがあります
      </span>
    </div>
    <p class="submit_bx1">
      <button @click="toConfirmStep()" class="submit1 bg_green step1do" :disabled="!commonState.agreeToTerms">
        確認画面
      </button>
    </p>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import { useCommonState } from '@/components/Register/composables/useCommonState'
import InputFormPlanParts from '@/components/Register/components/InputFormPlanParts.vue'
import InputFormCompany from '@/components/lib/user/components/InputFormCompany.vue'
import InputFormIndividual from '@/components/lib/user/components/InputFormIndividual.vue'
import { validateUserParams } from '@/validations/userValidations'
import { convertUserDataForSave } from '@/utils/userUtils'
import { validateUserPaymentMethod } from '@/validations/userPaymentMethodValidations'

export default defineComponent({
  components: { InputFormIndividual, InputFormCompany, InputFormPlanParts },
  setup() {
    const vue = getCurrentInstance().proxy
    const commonState = useCommonState()
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    const hasError = computed(() => {
      return Object.keys(commonState.errors).length > 0 || Object.keys(userErrorState.errors).length > 0
    })
    const isCompanyMode = computed(() => {
      return userState.is_company
    })

    function onContractModeChange(eventObj) {
      userState.is_company = eventObj.isCompany
      // 支払い方法だけはデフォルトに戻す
      userState.payment_method_id = '1'
    }

    function validateInputStep() {
      const state = commonState

      state.errors = {}
      userErrorState.errors = {}
      let result = true

      if (!state.planPart1) {
        state.errors['planPart1'] = true
        state.errors['planPart1_required'] = true
        result = false
      }
      if (!state.planPart2) {
        state.errors['planPart2'] = true
        state.errors['planPart2_required'] = true
        result = false
      }

      if (!state.bagSize) {
        state.errors['bagSize'] = true
        state.errors['bagSize_required'] = true
        result = false
      }

      const [isUserParamsOk, userParamErrors] = validateUserParams(userState, state.planId, state.areaInfos)
      if (!isUserParamsOk) {
        userErrorState.errors = { ...userErrorState.errors, ...userParamErrors }
        result = false
      }

      const [isUserPaymentMethodOk, userPaymentMethodErrors] = validateUserPaymentMethod(userState, state.planId, state.bagSize)
      if (!isUserPaymentMethodOk) {
        userErrorState.errors = { ...userErrorState.errors, ...userPaymentMethodErrors }
        result = false
      }

      return result
    }

    function toConfirmStep() {
      convertUserDataForSave(userState)

      if (!validateInputStep()) { return }
      vue.$emit('to-confirm-step')
    }

    return {
      commonState,
      // computed
      hasError,
      isCompanyMode,
      // methods
      onContractModeChange,
      toConfirmStep,
    }
  }
})
</script>

<style lang="scss" scoped>
.step1do:disabled {
  opacity: 0.6;
}
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}

.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
</style>
